import gql from 'graphql-tag';


export const TRANSLATIONS_DASHBOARD = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            stands
            storekeeper
            monitoring
            schedule
          }
          dashboard {
            planText
            monitoringText
            standText
            clearanceText
          }
        }
      }
    }
  }
`;
