import React, { Fragment, } from 'react';
import {
  shape, string, func, object, bool,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';

const VehicleGroupFrom = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.isInternal}
      input={form.isInternal}
    >

      <InputCheckbox
        value={values.isInternal}
        onChange={(checked) => onChange(form.isInternal.name, checked)}
        text={translations.form.isInternalText}
      />

    </FormRow>

    <FormRow
      label={translations.form.freeEntrance}
      input={form.freeEntrance}
    >

      <InputCheckbox
        value={values.freeEntrance}
        onChange={(checked) => onChange(form.freeEntrance.name, checked)}
        text={translations.form.freeEntranceText}
      />

    </FormRow>

    <FormRow
      label={translations.form.freeExit}
      input={form.freeExit}
    >

      <InputCheckbox
        value={values.freeExit}
        onChange={(checked) => onChange(form.freeExit.name, checked)}
        text={translations.form.freeExitText}
      />

    </FormRow>

  </Fragment>
);


VehicleGroupFrom.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    isInternal: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    freeEntrance: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    freeExit: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    isInternal: bool,
    freeEntrance: bool,
    freeExit: bool,
  }).isRequired,
  onChange: func.isRequired,
};


export default VehicleGroupFrom;
