import React from 'react';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import { TransitionGroup, CSSTransition, } from 'react-transition-group';

import Notification from './Notification';

import StyledNotificationsArray from './styles/StyledNotificationsArray';


const NotificationsArray = ({ notifications, onRemove, }) => (
  <StyledNotificationsArray>
    <TransitionGroup>
      {notifications.map((item) => (
        <CSSTransition
          classNames="notifications--trans"
          timeout={350}
          key={item.id}
        >
          <Notification
            data={item}
            onRemove={onRemove}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </StyledNotificationsArray>
);


NotificationsArray.propTypes = {
  notifications: arrayOf(shape({
    id: string.isRequired,
  })).isRequired,
  onRemove: func.isRequired,
};


export default NotificationsArray;
