import gql from 'graphql-tag';


export const TRANSLATIONS_STOREKEEPER = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            close
            storekeeper
            btnSave
          }
          form {
            no
            truckRegistrationNumber
            transportationType
            platform
          }
          centralAppStorekeeper {
            tabAllPlatforms
            tabPlatform
            placeholderQueuePlatform
            infoQueueSelectPlatform
            infoQueueNoTransportation
            btnQueueCall
            btnQueueDetail
            notifTitleCallSuccess
            notifTitleCallError
            modalCallTitle
            placeholderModalCallPlatform
            notifTitleSetPlatformOpenSuccess
            notifTitleSetPlatformOpenError
            notifTitleSetPlatformCloseSuccess
            notifTitleSetPlatformCloseError
            btnOpenPlatform
            btnClosePlatform
            platformCapacity
            infoPlatformClosed
            btnPlatformCall
            btnPlatformCallQueue
            notifTitleClearedSuccess
            notifTitleClearedError
            notifTitleRepeatCallSuccess
            notifTitleRepeatCallError
            notifTitleCancelSuccess
            notifTitleCancelError
            notifTitleContinueSuccess
            notifTitleContinueError
            notifTitleFinishSuccess
            notifTitleFinishError
            btnTransportationClear
            btnTransportationRepeatCall
            btnTransportationCancel
            btnTransportationForward
            btnTransportationDelay
            btnTransportationForm
            btnTransportationFinish
            timeStarted
            titleModalInterrupt
            btnInterruptParking
            btnInterruptLong
            titleModalForward
            btnForward
            placeholderModalForwardPlatform
            titleModalFinish
            notifTitleInterruptSuccess
            notifTitleInterruptError
            notifTitleDelaySuccess
            notifTitleDelayError
            notifTitleForwardSuccess
            notifTitleForwardError
            btnFinish
            labelSolve
            textSolve
            labelManual
            textManual
            placeholderModalFinishPlatforms
            titleModalDetail
            notifTitleFormSaved
            platformFreeSpace
            btnTransportationContinue
            textModalForward
            titleQueue
          }
        }
        shared {
          storekeeperClearingStates {
            called
            clearing
            interrupted
            solving
            delayed
            forwarded
          }
        }
      }
    }
  }
`;

export const TRANSLATIONS_SMS_CHAT = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            close
            storekeeper
            btnSave
            back
            create
          }
          form {
            smsText
            sendSms
            sentSucceded
          }
        }
      }
    }
  }
`;
