import React from 'react';
import styled from 'styled-components';
import { func, object, } from 'prop-types';

import Button from '../../../../atoms/Button/Button';


const StyledRoot = styled.div`
  text-align: right;
  margin: 0 -0.25rem 1rem -0.25rem;
`;


const BlockAddReservationForm = ({
  onAdd,
  translations,
}) => (
  <StyledRoot>
    <Button
      color="success"
      size="sm"
      onClick={onAdd}
    >
      {translations.transportation.connectedTransports.buttonAddNext}
    </Button>
  </StyledRoot>
);


BlockAddReservationForm.propTypes = {
  onAdd: func.isRequired,
  translations: object.isRequired,
};


export default BlockAddReservationForm;
