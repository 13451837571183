import gql from 'graphql-tag';


/**
 * Roles
 */
export const SUBSCRIPTION_ROLES = gql`
  subscription Roles {
    roles {
      id
      name
    }
  }
`;


/**
 * Users
 */
export const SUBSCRIPTION_USERS = gql`
  subscription Users {
    users {
      id
      username
      block
      roles {
        id
        name
      }
    }
  }
`;
