import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalInterruptView from './ModalInterruptView';


class ModalInterrupt extends Component {
  /**
   * Interrupt
   */
  handleInterruptTransportation = (mutationInterrupt) => {
    const {
      platformId,
      transportationId,
    } = this.props;

    mutationInterrupt({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Interrupt - Completed
   */
  handleInterruptTransportationCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleInterruptSuccess,
    });
  }

  /**
   * Interrupt - Error
   */
  handleInterruptTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleInterruptError,
    });
  }


  /**
   * Delay
   */
  handleDelayTransportation = (mutationDelay) => {
    const {
      platformId,
      transportationId,
    } = this.props;

    mutationDelay({
      variables: {
        platformId,
        transportationId,
      },
    });
  }

  /**
   * Delay - Completed
   */
  handleDelayTransportationCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleDelaySuccess,
    });
  }

  /**
   * Delay - Error
   */
  handleDelayTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleDelayError,
    });
  }


  render() {
    const {
      // data
      translations,
      // methods
      onToggle,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalInterrupt}
        disablePadding
      >
        <ModalInterruptView
          // data
          translations={translations}
          // methods
          onToggle={onToggle}
          // delay
          onDelayTransportation={this.handleDelayTransportation}
          onDelayTransportationCompleted={this.handleDelayTransportationCompleted}
          onDelayTransportationError={this.handleDelayTransportationError}
          // interrupt
          onInterruptTransportation={this.handleInterruptTransportation}
          onInterruptTransportationCompleted={this.handleInterruptTransportationCompleted}
          onInterruptTransportationError={this.handleInterruptTransportationError}
        />
      </Modal>
    );
  }
}


ModalInterrupt.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(ModalInterrupt);
