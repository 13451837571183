import React, { Component, } from 'react';
import {
  arrayOf, func, object, shape, string,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_PLATFORMS, } from '../../gql/queries';
import { CHANGE_PLATFORM_STOREKEEPER_QUEUE, } from '../../gql/mutations';
import { withUser, } from '../../../../logic/auth/withUser';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import Item from './Item';
import StyledQueueList from './styles/StyledQueueList';
import PartInfo from '../../../../components/Parts/PartInfo';


class QueuePlatforms extends Component {
  constructor(props) {
    super(props);

    const { queue, platform, } = props;

    this.state = ({
      filteredQueue: platform
        ? this.filterQueue({ queue, platformId: platform.id, })
        : [],
    });
  }


  state = {
    filteredQueue: [],
  }


  componentDidUpdate(prevProps) {
    const { queue, platform, } = this.props;

    if (platform && (queue !== prevProps.queue || platform !== prevProps.platform)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filteredQueue: this.filterQueue({ queue, platformId: platform.id, }),
      });
    }
  }


  filterQueue = ({ queue, platformId, }) => (
    queue.filter((x) => (
      (x.platformId && platformId === x.platformId)
      || (!x.platformId && x.possiblePlatforms.includes(platformId))
    ))
  )


  render() {
    const { filteredQueue, } = this.state;
    const {
      // data
      platform,
      languageId,
      translations,
      translationsCommon,
      resources,
      // functions
      onCall,
      onDetail,
      onSmsChat,
    } = this.props;

    return (
      <div>

        <div className="storekeeperQueue--platforms-select">
          <Query
            query={QUERY_PLATFORMS}
            variables={{
              languageId,
              platformIds: resources.storekeeper_access_by_platform_id
                ? resources.storekeeper_access_by_platform_id.constraint
                : undefined,
            }}
          >
            {({
              data, loading, error, client,
            }) => (
              <InputSelect
                value={platform}
                options={
                  (data && data.fetchCentralPlatforms)
                    ? data.fetchCentralPlatforms
                    : []
                }
                onChange={(option) => client.mutate({
                  mutation: CHANGE_PLATFORM_STOREKEEPER_QUEUE,
                  variables: {
                    id: option.id,
                    name: option.name,
                  },
                })}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isLoading={loading}
                error={error}
                placeholder={translations.centralAppStorekeeper.placeholderQueuePlatform}
              />
            )}
          </Query>
        </div>

        {(platform && filteredQueue.length > 0) && (
          <StyledQueueList>
            {filteredQueue.map((item) => (
              <Item
                key={item.id}
                item={item}
                translations={translations}
                translationsCommon={translationsCommon}
                onCall={onCall}
                onDetail={onDetail}
                onSmsChat={onSmsChat}
              />
            ))}
          </StyledQueueList>
        )}

        {(platform && filteredQueue.length < 1) && (
          <PartInfo>
            <span className="color--watermark">
              {translations.centralAppStorekeeper.infoQueueNoTransportation}
            </span>
          </PartInfo>
        )}

        {!platform && (
          <PartInfo>
            <span className="color--watermark">
              {translations.centralAppStorekeeper.infoQueueSelectPlatform}
            </span>
          </PartInfo>
        )}
      </div>
    );
  }
}


QueuePlatforms.propTypes = {
  // data
  queue: arrayOf(object).isRequired,
  platform: shape({
    id: string.isRequired,
    name: string.isRequired,
  }),
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
  // functions
  onCall: func.isRequired,
  onDetail: func.isRequired,
  onSmsChat: func.isRequired,
};

QueuePlatforms.defaultProps = {
  platform: null,
};


export default withUser(QueuePlatforms);
