import React from 'react';
import {
  arrayOf, string, object, func,
} from 'prop-types';

import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import EditLogic from './EditLogic';
import FlowChart from './FlowChart';
import Actions from './Actions';

import StyledTransportationAdmin from './styles/StyledTransportationAdmin';

const TransportationAdminView = ({
  // data
  detail,
  tabs,
  transportationId,
  languageId,
  translations,
  // methods
  onToggle,
  onEdited,
  onDeleted,
}) => (
  <StyledTransportationAdmin>

    <Actions
      detail={detail}
      translations={translations}
      onToggle={onToggle}
      onDeleted={onDeleted}
    />

    <EditLogic
      data={detail}
      translations={translations}
      languageId={languageId}
      onEdited={onEdited}
    />

    <FlowChart
      tabs={tabs}
      transportationId={transportationId}
      translations={translations}
      languageId={languageId}
      arrival={detail.arrival}
      departure={detail.departure}
    />

    <Row>
      <Col>
        <Button
          onClick={onToggle}
          shape="outline"
        >
          {translations.common.close}
        </Button>
      </Col>
    </Row>

  </StyledTransportationAdmin>
);


TransportationAdminView.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onEdited: func,
  onDeleted: func,
};

TransportationAdminView.defaultProps = {
  onEdited: undefined,
  onDeleted: undefined,
};


export default TransportationAdminView;
