import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_UPDATE_COMPANY, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import CompanyForm from '../forms/CompanyForm';
import FormBackground from '../../../../components/Form/FormBackground';


const CompanyUpdateView = ({
  // data
  resources,
  detailForm,
  translations,
  // methods
  onChange,
  onToggle,
  onUpdate,
  onUpdateCompleted,
  onUpdateError,
}) => (
  <FormBackground>
    <CompanyForm
      {...detailForm}
      translations={translations}
      onChange={onChange}
    />

    <Mutation
      mutation={MUTATION_UPDATE_COMPANY}
      onCompleted={onUpdateCompleted}
      onError={onUpdateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                type="button"
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              {resources.directory_company_write && (
                <ButtonLoader
                  type="button"
                  color="success"
                  onClick={() => onUpdate(mutate)}
                  disabled={!detailForm.isValid}
                  isLoading={loading}
                >
                  {translations.common.update}
                </ButtonLoader>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


CompanyUpdateView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  detailForm: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateError: func.isRequired,
  onUpdateCompleted: func.isRequired,
};


export default CompanyUpdateView;
