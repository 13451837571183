import gql from 'graphql-tag';


/**
 * Stand
 */
export const QUERY_STAND_TRANSLATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          errors {
            SOMETHING_HAPPENED
          }
          common {
            yes
            no
            stands
            home
            filter
            detail
            from
            to
          }
          stand {
            callStates {
              waiting
              called
              clearing
            }
            messageTitleCalled
            messageTitleCallCanceled
            doCall
            undoCall
            repeatCall
            standHeader
            noStandsText
            selectStandPlaceholder
            confirmStandWizardModalTitle
            confirmStandWizardModalText
            labelSelectStand
            standTableNoData
            thActions
            btnTransportationActionActive
            btnTransportationActionInactive
            btnVisitorAction
            labelMoveToNextStand
            thActions
            errorStandMissingTab
            btnCreateTransportationAndRegistration
          }
          visitor {
            createVisitor
          }
          transportation {
            createTransportation
          }
        }
      }
    }
  }
`;

export const TRANSLATION_STAND_CONFIGURATION = ({
  visitorTab,
  tabs,
}) => {
  let tabNames = '';
  let colsVisitors = '';

  // Visitors
  if (visitorTab.enable) {
    // visitors - tab name, columns
    tabNames += `${visitorTab.name}
    `;

    for (let i = 0; i < visitorTab.columns.length; i++) {
      colsVisitors += `${visitorTab.columns[i]}
      `;
    }
  }


  const standParams = {};

  // tabs - tab name, columns
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    tabNames += `${tab.name}
    `;
    for (let j = 0; j < tab.columns.length; j++) {
      standParams[tab.columns[j]] = true;
    }
  }

  // parse columns to string
  let colsStand = '';
  const keys = Object.keys(standParams);
  for (let i = 0; i < keys.length; i++) {
    colsStand += `${keys[i]}
    `;
  }

  let standVisitorsDataColumns = '';
  if (visitorTab.enable) {
    standVisitorsDataColumns = `
    standVisitorsDataColumns {
      ${colsVisitors}
    }
    `;
  }

  const query = `
    query Dictionary($languageId: ID) {
      dictionary(languageId: $languageId) {
        dictionary {
          centralApp {
            stands {
              ${tabNames}
            }
            ${standVisitorsDataColumns}
            standDataColumns {
              ${colsStand}
            }
          }
        }
      }
    }
  `;
  return gql(query);
};


/**
 * Visitor
 */
export const TRANSLATION_VISITOR = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            created
            confirm
            departed
          }
          form {
            name
            info
          }
          visitor {
            createModalVisitorTitle
            visitorDepartureModalTitle
            visitorDepartureModalText
          }
        }
      }
    }
  }
`;


/**
 * Stand Wizard
 */
export const TRANSLATION_STAND_WIZARD = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            update
            confirm
            automatic
            manual
            yes
            no
          }
          stand {
            actionWizardTitle
            actionWizardTabFinish
            transportWasMoved
            modalSaveChangesTitle
            modalSaveChangesText
            selectStandPlaceholder
            automaticSelectText
            moveToNextStand
            textTabActionNoForm
          }
        }
      }
    }
  }
`;
