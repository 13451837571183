import React, { Component, } from 'react';
import {
  string, shape, bool, object, arrayOf,
} from 'prop-types';

import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import Collapse from '../../../../atoms/Collapse/Collapse';
import FormBackground from '../../../../components/Form/FormBackground';

import Arrow from '../../../../styles/icons/Arrow';


class FlowChartGateAction extends Component {
  state = {
    isOpen: false,
  };


  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen, }));
  }

  render() {
    const { isOpen, } = this.state;
    const {
      data: {
        title,
        start,
      },
    } = this.props;


    return (
      <>
        <h5>
          {title}
          <ButtonIcon
            className="transpDetail--flow-right-toggle"
            onClick={this.handleToggle}
            size="sm"
            thin
            color="tertiary"
            disabled={!start}
          >
            <Arrow className={`transpDetail--flow-right-icon ${isOpen ? ' transpDetail--flow-right-icon-open' : 'transpDetail--flow-right-icon-close'}`} />
          </ButtonIcon>
        </h5>
        <Collapse isOpen={isOpen}>
          {isOpen && (
            <FormBackground />
          )}
        </Collapse>
      </>
    );
  }
}


FlowChartGateAction.propTypes = {
  data: shape({
    title: string.isRequired,
    start: bool.isRequired,
    form: object,
    pictures: arrayOf(string),
  }).isRequired,
};


export default FlowChartGateAction;
