import React from 'react';
import { object, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { TRANSLATIONS_DASHBOARD, } from '../gql/translations';
import PageView from '../../../atoms/PageView/PageView';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import ModuleGrid from '../components/ModuleGrid/ModuleGrid';

import StyledDashboard from '../components/styles/StyledDashboard';
import Planning from '../../../styles/icons/app/Planning';
import LiveMonitor from '../../../styles/icons/app/LiveMonitor';
import Stand from '../../../styles/icons/app/Stand';
import Storekeeper from '../../../styles/icons/app/Storekeeper';


const DashboardPage = ({ translations, }) => (
  <PageView>

    <Breadcrumb>
      <BreadcrumbItem>{translations.common.home}</BreadcrumbItem>
    </Breadcrumb>

    <StyledDashboard>
      <ul className="dashboard--list">
        <ModuleGrid
          icon={<Planning />}
          title={translations.common.schedule}
          text={translations.dashboard.planText}
          to="/transportation/schedule"
        />

        <ModuleGrid
          icon={<LiveMonitor />}
          title={translations.common.monitoring}
          text={translations.dashboard.monitoringText}
          to="/monitoring"
        />

        <ModuleGrid
          icon={<Stand />}
          title={translations.common.stands}
          text={translations.dashboard.standText}
          to="/stands"
        />

        <ModuleGrid
          icon={<Storekeeper />}
          title={translations.common.storekeeper}
          text={translations.dashboard.clearanceText}
          to="/storekeeper"
        />
      </ul>
    </StyledDashboard>
  </PageView>
);


DashboardPage.propTypes = {
  translations: object.isRequired,
};


export default withTranslations(TRANSLATIONS_DASHBOARD)(DashboardPage);
