import gql from 'graphql-tag';


/**
 * Transportation
 */
export const MUTATION_CREATE_TRANSPORTATION = gql`
  mutation CreateTransportation(
    $reservations: [reservationInputType],
    $company: companyInputType,
    $driver: driverInputType,
    $truck: truckInputType,
    $trailer: trailerInputType,
    $autoStartOnCombinedTransportation: Boolean,
    $priority: Int,
    $note: String
  ) {
    createTransportation(
      reservations: $reservations,
      company: $company,
      driver: $driver,
      truck: $truck,
      trailer: $trailer,
      autoStartOnCombinedTransportation: $autoStartOnCombinedTransportation,
      priority: $priority,
      note: $note
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_TRANSPORTATION = gql`
  mutation UpdateTransportation(
    $id: ID!,
    $isMonitoring: Boolean!,
    $reservation: reservationInputType,
    $company: companyInputType,
    $driver: driverInputType,
    $truck: truckInputType,
    $trailer: trailerInputType,
    $priority: Int,
    $note: String
  ) {
    updateTransportation(
      id: $id,
      isMonitoring: $isMonitoring,
      reservation: $reservation,
      company: $company,
      driver: $driver,
      truck: $truck,
      trailer: $trailer,
      priority: $priority,
      note: $note
    )
  }
`;

export const MUTATION_DELETE_TRANSPORTATION = gql`
  mutation deleteTransportation($id: ID!) {
    deleteTransportation(id: $id)
  }
`;

export const MUTATION_UPDATE_GEN_FORM = gql`
  mutation CreateOrUpdateGenericFormValues($values: JSON, $transportationId: ID!, $genericFormId: ID!, $actionId: ID!) {
    createOrUpdateGenericFormValues(values: $values, transportationId: $transportationId, genericFormId: $genericFormId, actionId: $actionId)
  }
`;


/**
 * Common
 */
export const MUTATION_SCHEDULE_TIMELINE_FILTER = gql`
  mutation UpdateScheduleTimelineFilter($filter: ScheduleTimelineFilter!) {
    updateScheduleTimelineFilter(filter: $filter) @client
  }
`;
