import React from 'react';
import {
  shape, string, object, func, bool, number, arrayOf,
} from 'prop-types';

import Transportation from '../Transportation/Transportation';
import PlatformSectionClosed from './PlatformSectionClosed';
import PlatformSectionEmpty from './PlatformSectionEmpty';
import StyledPlatformSections from '../styles/StyledPlatformSections';


const PlatformView = ({
  // data
  platform,
  occupied,
  languageId,
  translations,
  translationsCommon,
  resources,
  // functions
  onCallTransportation,
  onCallTransportationCompleted,
  onCallTransportationError,
}) => {
  const hasEmptySection = platform.capacity > occupied;
  const showSectionClosed = !platform.opened && hasEmptySection;
  const showSectionEmpty = platform.opened && hasEmptySection;

  return (
    <StyledPlatformSections capacity={platform.capacity}>

      <ul>
        {platform.clearance.map((t) => (
          <Transportation
            key={t.id}
            transportation={t}
            languageId={languageId}
            translations={translations}
            translationsCommon={translationsCommon}
            resources={resources}
          />
        ))}
      </ul>

      {showSectionClosed && (
        <PlatformSectionClosed
          translations={translations}
        />
      )}

      {showSectionEmpty && (
        <PlatformSectionEmpty
          // data
          platform={platform}
          translations={translations}
          // functions
          onCallTransportation={onCallTransportation}
          onCallTransportationCompleted={onCallTransportationCompleted}
          onCallTransportationError={onCallTransportationError}
        />
      )}

    </StyledPlatformSections>
  );
};


PlatformView.propTypes = {
  platform: shape({
    id: string.isRequired,
    name: string.isRequired,
    opened: bool.isRequired,
    clearance: arrayOf(object).isRequired,
  }).isRequired,
  occupied: number.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
  onCallTransportation: func.isRequired,
  onCallTransportationCompleted: func.isRequired,
  onCallTransportationError: func.isRequired,
};


export default PlatformView;
