import React, { Fragment, } from 'react';
import {
  func, object, string, shape, arrayOf,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_EDIT_TRANSPORTATION, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import FormBackground from '../../../../components/Form/FormBackground';
import TransportationTruckForm from '../forms/TransportationTruckForm';
import TransportationDriverForm from '../forms/TransportationDriverForm';
import TransportationCompanyForm from '../forms/TransportationCompanyForm';
import EditReservationForm from './EditReservationForm';
import TransportationTrailerForm from '../forms/TransportationTrailerForm';
import TransportationOtherForm from '../forms/TransportationOtherForm';
import CompanyFilter from '../filters/CompanyFilter';
import DriverFilter from '../filters/DriverFilter';
import TruckFilter from '../filters/TruckFilter';
import RelatedTransportations from '../RelatedTransportations';


const EditView = ({
  // data
  data,
  detailForm,
  options,
  languageId,
  translations,
  // methods
  onChangeForm,
  onEdit,
  onEditError,
  onEditComplete,
  onFillFormValues,
  onAddNewCompany,
}) => (
  <FormBackground className="transportationAdmin--form">
    <Row>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleReservationForm}</legend>
          <EditReservationForm
            {...detailForm}
            optionsReservationTypes={options.reservationTypes}
            languageId={languageId}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <RelatedTransportations
          prev={data.prevTransport}
          next={data.nextTransport}
          translations={translations}
        />

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleCompanyForm}</legend>
          <CompanyFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterCompany}
            translations={translations}
          />
          <TransportationCompanyForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
          <Row>
            <Col textAlign="right">
              <Button
                color="success"
                size="sm"
                onClick={onAddNewCompany}
              >
                {translations.transportation.btnSaveAsNewCompany}
              </Button>
            </Col>
          </Row>
        </fieldset>

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleDriverForm}</legend>
          <DriverFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterDriver}
            translations={translations}
          />
          <TransportationDriverForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTruckForm}</legend>
          <TruckFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterTruck}
            translations={translations}
          />
          <TransportationTruckForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTrailerForm}</legend>
          <TransportationTrailerForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.otherForm}</legend>
          <TransportationOtherForm
            {...detailForm}
            languageId={languageId}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

      </Col>
    </Row>

    <Mutation
      mutation={MUTATION_EDIT_TRANSPORTATION}
      onCompleted={onEditComplete}
      onError={onEditError}
    >
      {(editMutation, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            customError={detailForm.customError}
            error={error}
          />
          <Row>
            <Col textAlign="right">
              <ButtonLoader
                type="button"
                color="success"
                onClick={() => onEdit(editMutation)}
                disabled={!detailForm.isValid}
                isLoading={loading}
              >
                {translations.common.update}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


EditView.propTypes = {
  // data
  data: shape({
    prevTransport: string,
    nextTransport: string,
  }).isRequired,
  detailForm: object.isRequired,
  options: shape({
    reservationTypes: arrayOf(object).isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onChangeForm: func.isRequired,
  onEdit: func.isRequired,
  onEditError: func.isRequired,
  onEditComplete: func.isRequired,
  onFillFormValues: func.isRequired,
  onAddNewCompany: func.isRequired,
};


export default EditView;
