import React from 'react';
import { object, func, string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_ROLE, } from '../../gql/queries';
import { TRANSLATIONS_ROLE, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import RoleUpdateLogic from './RoleUpdateLogic';


const RoleUpdate = ({
  roleId,
  translations,
  languageId,
  onToggle,
}) => (
  <Modal
    title={translations.settings.detailRoleTitle}
    isOpen
    onClose={onToggle}
    size="SM"
    disablePadding
  >
    <Query
      query={QUERY_ROLE}
      variables={{
        roleId,
        languageId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <ModalLoadingPart
                onBack={onToggle}
                btnBack={translations.common.back}
              />
            </div>
          );
        }

        if (error || !data.fetchRole) {
          return (
            <div className="app--padding">
              <ModalErrorPart
                onBack={onToggle}
                error={error}
                btnBack={translations.common.back}
              />
            </div>
          );
        }

        return (
          <RoleUpdateLogic
            // data
            data={data.fetchRole}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={onToggle}
          />
        );
      }}
    </Query>
  </Modal>
);


RoleUpdate.propTypes = {
  roleId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  onToggle: func.isRequired,
};


export default withTranslations(TRANSLATIONS_ROLE)(RoleUpdate);
