import gql from 'graphql-tag';


export const QUERY_SIDEBAR_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      language {
        id
        name
        code
      }
      dictionary {
        centralApp {
          common {
            appTitle
            menu
            dashboard
            transportation
            schedule
            history
            directory
            trucks
            vehicleGroups
            companies
            drivers
            stands
            monitoringModule
            monitoring
            notifications
            settings
            userSettings
            storekeeper
          }
        }
      }
    }
  }
`;
