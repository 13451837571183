import React from 'react';
import {
  shape, string, bool, func, object,
} from 'prop-types';

import Modal from '../../../../atoms/Modal/Modal';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';


const ConfirmWizard = ({
  // data
  modalData: {
    isOpen,
    id,
  },
  translations,
  // methods
  onToggle,
  onConfirm,
}) => (
  isOpen ? (
    <Modal
      isOpen
      onClose={onToggle}
      title={translations.stand.confirmStandWizardModalTitle}
      size="SM"
    >
      <Row formGroup>
        <Col>
          {translations.stand.confirmStandWizardModalText}
        </Col>
      </Row>
      <Row>
        <Col XS={12} SM={12}>
          <Button
            onClick={onToggle}
            shape="outline"
          >
            {translations.common.no}
          </Button>
        </Col>
        <Col XS={12} SM={12} textAlign="right">
          <Button
            onClick={() => {
              onToggle();
              onConfirm(id);
            }}
            color="success"
          >
            {translations.common.yes}
          </Button>
        </Col>
      </Row>
    </Modal>
  ) : null
);


ConfirmWizard.propTypes = {
  // data
  modalData: shape({
    isOpen: bool.isRequired,
    id: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onConfirm: func.isRequired,
};


export default ConfirmWizard;
