import React, { Component, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { RESERVATION_TYPES, } from '../../../../globals';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeFormValidations,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeMultipleValues,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { getReservationTypesOptions, parseTransportationMutationVariables, } from '../utils';
import { initTransportationForm, customValidationReservationForm, } from '../forms/structure';
import { QUERY_ALL_COMPANIES, } from '../../gql/queries';
import CompanyCreate from '../../../Directory/components/CompanyCreate/CompanyCreate';
import EditView from './EditView';


class EditLogic extends Component {
  constructor(props) {
    super(props);

    const {
      translations,
      data: {
        reservation,
        company,
        driver,
        truck,
        trailer,
        id,
        priority,
        priorityLevelName,
        note,
      },
    } = props;

    this.state = {
      detailForm: {
        ...initTransportationForm,
        values: {
          ...initTransportationForm.values,
          ...reservation,
          resDate: reservation.resDate ? new Date(reservation.resDate) : null,
          resTimeFrom: reservation.resTimeFrom ? new Date(reservation.resTimeFrom) : null,
          resTimeTo: reservation.resTimeTo ? new Date(reservation.resTimeTo) : null,
          ...company,
          ...driver,
          ...truck,
          ...trailer,
          id,
          priority: {
            id: priority,
            name: priorityLevelName,
          },
          note,
        },
      },
      options: {
        reservationTypes: getReservationTypesOptions(translations),
      },
      modalNewCompany: {
        isOpen: false,
        presetValues: null,
      },
    };
  }


  /**
   * Form - onChange
   */
  handleChangeForm = (name, value) => {
    const { detailForm, } = this.state;

    switch (name) {
      // Transportation Type - reset Platform
      case 'resTransportationType': {
        const newDetailForm = changeMultipleValues(detailForm, [
          {
            name,
            value,
          },
          {
            name: 'resPlatform',
            value: null,
          },
        ]);
        this.setState({ detailForm: newDetailForm, });
        break;
      }

      // Reservation Type - change validation
      case 'resReservationType': {
        const resTypeDay = value && value.id === RESERVATION_TYPES.DAY;
        const resTypeInterval = value && (
          value.id === RESERVATION_TYPES.INTERVAL
          || value.id === RESERVATION_TYPES.INTERVAL_PLATFORM
        );
        const resTypeIntervalPlatform = value && value.id === RESERVATION_TYPES.INTERVAL_PLATFORM;

        const newForm = changeFormValidations(
          detailForm,
          [
            {
              name: 'resPlatform',
              validation: { required: resTypeIntervalPlatform, },
            },
            {
              name: 'resDate',
              validation: { required: resTypeDay, },
            },
            {
              name: 'resTimeFrom',
              validation: { required: resTypeInterval, },
            },
            {
              name: 'resTimeTo',
              validation: { required: resTypeInterval, },
            },
          ],
        );

        const newDetailForm = changeAndValidateInput(newForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }

      // Default
      default: {
        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }
    }
  }


  /**
   * Fill form values
   */
  handleFillFormValues = (neValues) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeMultipleValues(detailForm, neValues);
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Modal New Company - Close
   */
  closeModalAddNewCompany = () => {
    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: false,
        presetValues: null,
      },
    }));
  }

  /**
   * Modal New Company - Open
   */
  handleAddNewCompany = () => {
    const { detailForm: { values, }, } = this.state;

    const parsedCompanyData = {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      info: values.compInfo,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
    };

    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: true,
        presetValues: parsedCompanyData,
      },
    }));
  }


  /**
   * Modal New Company - Company Added
   */
  handleAddNewCompanyCompleted = () => {
    const { client, } = this.props;

    client.query({
      query: QUERY_ALL_COMPANIES,
      fetchPolicy: 'network-only',
    });
  }


  /**
   * Edit mutation
   *  - handleEdit
   *  - handleCompete
   *  - handleError
   */
  handleEdit = (editMutation) => {
    const { detailForm, detailForm: { values, }, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm, customValidationReservationForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const parsedValues = parseTransportationMutationVariables(values);
      editMutation({
        variables: {
          ...parsedValues,
          id: values.id,
          // TODO I don't like this - probably make two queries - monitoring and edit
          isMonitoring: true,
        },
      });
    }
  }


  handleEditComplete = () => {
    const {
      translations, addNotification, onEdited,
    } = this.props;

    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
    if (onEdited) onEdited();
  }

  handleEditError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const {
      detailForm,
      options,
      modalNewCompany,
    } = this.state;
    const {
      data,
      languageId,
      translations,
    } = this.props;

    return (
      <>
        {modalNewCompany.isOpen && (
          <CompanyCreate
            // data
            modalData={{ isOpen: true, }}
            presetValues={modalNewCompany.presetValues}
            // methods
            onToggle={this.closeModalAddNewCompany}
            onCreated={this.handleAddNewCompanyCompleted}
          />
        )}

        <EditView
          // data
          data={data}
          detailForm={detailForm}
          options={options}
          languageId={languageId}
          translations={translations}
          // methods
          onChangeForm={this.handleChangeForm}
          onEdit={this.handleEdit}
          onEditComplete={this.handleEditComplete}
          onEditError={this.handleEditError}
          onFillFormValues={this.handleFillFormValues}
          onAddNewCompany={this.handleAddNewCompany}
        />

      </>
    );
  }
}


EditLogic.propTypes = {
  // data
  data: shape({
    id: string.isRequired,
    reservation: shape({
      resNo: string.isRequired,
      resTransportationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resReservationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resPlatform: shape({
        id: string.isRequired,
        name: string.isRequired,
      }),
      resTimeFrom: string,
      resTimeTo: string,
      resDate: string,
    }).isRequired,
    company: shape({
      compName: string.isRequired,
      compIdentificationNumber: string.isRequired,
      compVatNumber: string.isRequired,
      compStreet: string.isRequired,
      compCity: string.isRequired,
      compZip: string.isRequired,
      compState: string.isRequired,
      compContactPerson: string.isRequired,
      compEmail: string.isRequired,
      compPhoneNumber: string.isRequired,
    }).isRequired,
    driver: shape({
      driverName: string.isRequired,
      driverPhoneNumber: string.isRequired,
    }).isRequired,
    truck: shape({
      truckRegistrationNumber: string.isRequired,
    }).isRequired,
    trailer: shape({
      trailerRegistrationNumber: string.isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  client: object.isRequired,
  // methods
  addNotification: func.isRequired,
  onEdited: func,
};

EditLogic.defaultProps = {
  onEdited: undefined,
};


export default pipe(
  withNotifications,
  withApollo,
)(EditLogic);
