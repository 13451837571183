/**
 * Date - addHours
 */
export const addHoursToDate = (d, h) => {
  const newDate = new Date();
  newDate.setTime(d.getTime() + (h * 60 * 60 * 1000));
  return newDate;
};


/**
 * Date - Validation
 */
export const isValidDate = (d) => d && Object.prototype.toString.call(d) === '[object Date]' && !Number.isNaN(d);


/**
 * Time -> Human
 */
export const parseTimeToHuman = (d, localization = 'cs-CZ') => {
  if (!d) return '';
  return d.toLocaleTimeString(
    localization,
    { hour: '2-digit', minute: '2-digit', }
  );
};


/**
 * Date -> Human
 */
export const parseDateToHuman = (d, showTime = true, localization = 'cs-CZ') => {
  if (!d) return '';
  const date = d.toLocaleDateString(localization);
  const time = parseTimeToHuman(d, localization);

  return (`${date}${showTime ? ` ${time}` : ''}`);
};


/**
 * Milliseconds -> Human
 */
export const parseMillisecondsToHumanTime = (milliseconds) => {
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return `${hours}h ${(minutes < 10) ? `0${minutes}` : minutes}m`;
};


export const roundToLowerHour = (date) => {
  const ignore = 60 * 60 * 1000;
  return new Date(Math.floor(date.getTime() / ignore) * ignore);
};


export const clearSecondsAndMilliseconds = (d) => {
  if (!d) return d;
  const newDate = new Date(d);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};


export const clearTime = (d) => {
  if (!d) return d;
  const newDate = new Date(d);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};


export const setDateTodayMinTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0); // eslint-disable-line


export const setDateTodayMaxTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999); // eslint-disable-line
