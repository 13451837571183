import React from 'react';
import { func, object, string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_TRANSPORTATION_GENERIC_FORM, } from '../../../gql/queries';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalLoadingPart from '../../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../../components/modal/ModalErrorPart';
import ModalDetailLogic from './ModalDetailLogic';

// PRIDAT SMS

const ModalDetail = ({
  transportationId,
  languageId,
  translations,
  onToggle,
  ...rest
}) => (
  <Modal
    title={translations.centralAppStorekeeper.titleModalDetail}
    isOpen
    size="SM"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_TRANSPORTATION_GENERIC_FORM}
      variables={{
        languageId,
        transportationId,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <ModalLoadingPart
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }
        if (error || !data.fetchStoreKeeperGenericFormConfiguration) {
          return (
            <ModalErrorPart
              error={error}
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }
        return (
          <ModalDetailLogic
            genFormData={data.fetchStoreKeeperGenericFormConfiguration}
            transportationId={transportationId}
            languageId={languageId}
            translations={translations}
            onToggle={onToggle}
            {...rest}
          />
        );
      }}
    </Query>
  </Modal>
);


ModalDetail.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default ModalDetail;
