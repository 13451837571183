import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import TextArea from '../../../../atoms/TextArea/TextArea';


const DriverForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.personName}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.phoneNumber}
    >
      <Input
        type="text"
        value={values.phoneNumber}
        onChange={(e) => onChange(form.phoneNumber.name, e.target.value)}
        status={form.phoneNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.info}
      input={form.info}
    >
      <TextArea
        type="text"
        value={values.info}
        onChange={(e) => onChange(form.info.name, e.target.value)}
        status={form.info.status}
      />
    </FormRow>

  </Fragment>
);


DriverForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    phoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    info: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    phoneNumber: string,
    info: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default DriverForm;
