import React from 'react';
import {
  oneOfType, arrayOf, node, bool, string, func, object,
} from 'prop-types';

import { QUERY_MODAL_TRANSLATIONS, } from './queries';
import { withTranslations, } from '../../logic/translations/withTranslations';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import ModalErrorPart from '../../components/modal/ModalErrorPart';
import ModalBase from './ModalBase';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';


const Modal = ({
  // data
  isOpen,
  title,
  color,
  disablePadding,
  disableClickOutside,
  size,
  children,
  translations,
  // methods
  onClose,
  closeOnEsc,
}) => {
  if (!isOpen) return null;
  return (
    <ModalBase
      // data
      isOpen={isOpen}
      disableClickOutside={disableClickOutside}
      size={size}
      // methods
      closeOnEsc={closeOnEsc}
      onClose={onClose}
    >

      <ModalHeader
        color={color}
        onClose={onClose}
      >
        {title}
      </ModalHeader>

      <ErrorBoundary
        renderError={() => (
          <ModalBody>
            <ModalErrorPart
              addPadding={disablePadding}
              onBack={onClose}
              btnBack={translations.common.back}
            />
          </ModalBody>
        )}
      >
        <ModalBody
          disablePadding={disablePadding}
        >
          {children}
        </ModalBody>
      </ErrorBoundary>

    </ModalBase>
  );
};


Modal.propTypes = {
  isOpen: bool.isRequired,
  disableClickOutside: bool,
  disablePadding: bool,
  closeOnEsc: bool,
  size: string,
  color: string,
  title: string,
  translations: object.isRequired,
  onClose: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


Modal.defaultProps = {
  disableClickOutside: true,
  disablePadding: false,
  closeOnEsc: false,
  size: undefined,
  color: undefined,
  title: '',
};


export default withTranslations(QUERY_MODAL_TRANSLATIONS)(Modal);
