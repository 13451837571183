import React, { Fragment, } from 'react';
import {
  bool, shape, arrayOf, string, object, func, number,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_HISTORY, QUERY_DD_TRANSPORTATION_TYPES, } from '../../gql/queries';
import {
  parseDateToHuman,
  parseMillisecondsToHumanTime,
  setDateTodayMinTime,
  setDateTodayMaxTime,
} from '../../../../logic/date';
import { getArrivalStateOptions, } from './utils';
import { getReservationTypesOptions, getReservationTypeColor, } from '../utils';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Badge from '../../../../atoms/Badge/Badge';

import Search from '../../../../styles/icons/Search';


const COL_COUNT = 10;
const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    no: '',
    transportationType: null,
    truckRegistrationNumber: '',
    trailerRegistrationNumber: '',
    reservationType: null,
    arrivalState: null,
    reserved: null,
    arrivalTimeFrom: null,
    arrivalTimeTo: null,
    departureTimeFrom: null,
    departureTimeTo: null,
    clearanceLengthFrom: '',
    clearanceLengthTo: '',
  },
};


const Header = ({
  // data
  filter,
  translations,
  languageId,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <THSortable
        title="#"
        name="id"
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.no}
        name="no"
        isActiveFilter={filter.params.no !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.transportationType}
        name="transportationTypeName"
        isActiveFilter={!!filter.params.transportationType}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.reservationType}
        name="reservationTypeName"
        isActiveFilter={!!filter.params.reservationType}
        filter={filter}
        style={{ minWidth: '15rem', width: '15rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.truckRegistrationNo}
        name="truckRegistrationNumber"
        isActiveFilter={filter.params.truckRegistrationNumber !== ''}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.arrivalState}
        name="arrivalState"
        isActiveFilter={!!filter.params.arrivalState}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.arrivalTime}
        name="arrivalTime"
        isActiveFilter={!!filter.params.arrivalTimeFrom || !!filter.params.arrivalTimeTo}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.departureTime}
        name="departureTime"
        isActiveFilter={!!filter.params.departureTimeFrom || !!filter.params.departureTimeTo}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.clearanceLength}
        name="clearanceTime"
        isActiveFilter={filter.params.clearanceLengthFrom !== '' || filter.params.clearanceLengthTo !== ''}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '4rem', width: '4rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th />

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.no}
          active={filter.params.no !== ''}
          onChange={(e) => onChangeParam('no', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_TRANSPORTATION_TYPES}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.transportationType}
              active={!!filter.params.transportationType}
              options={(dropdownData.data && dropdownData.data.fetchAllTransportationTypes)
                ? dropdownData.data.fetchAllTransportationTypes
                : []
              }
              onChange={(option) => onChangeParam('transportationType', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th>
        <InputSelect
          value={filter.params.reservationType}
          active={!!filter.params.reservationType}
          options={getReservationTypesOptions(translations)}
          onChange={(option) => onChangeParam('reservationType', option, false)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable
          placeholder={translations.common.filter}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.truckRegistrationNumber}
          active={filter.params.truckRegistrationNumber !== ''}
          onChange={(e) => onChangeParam('truckRegistrationNumber', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <InputSelect
          value={filter.params.arrivalState}
          active={!!filter.params.arrivalState}
          options={getArrivalStateOptions(translations)}
          onChange={(option) => onChangeParam('arrivalState', option, false)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable
          placeholder={translations.common.filter}
          size="sm"
        />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.arrivalTimeFrom}
          active={!!filter.params.arrivalTimeFrom}
          clearable
          onChange={(newDate) => onChangeParam('arrivalTimeFrom', newDate, false)}
        />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.departureTimeFrom}
          active={!!filter.params.departureTimeFrom}
          clearable
          onChange={(newDate) => onChangeParam('departureTimeFrom', newDate, false)}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.transportation.placeholderHistoryClearanceGreaterThan}
          autoComplete="off"
          value={filter.params.clearanceLengthFrom}
          active={filter.params.clearanceLengthFrom !== ''}
          onChange={(e) => onChangeParam('clearanceLengthFrom', e.target.value)}
          size="sm"
        />
      </th>

      <th />

    </tr>

    <tr>

      <th />

      <th />

      <th />

      <th />

      <th />

      <th />

      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.arrivalTimeTo}
          active={!!filter.params.arrivalTimeTo}
          clearable
          onChange={(newDate) => onChangeParam('arrivalTimeTo', newDate, false)}
        />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.departureTimeTo}
          active={!!filter.params.departureTimeTo}
          clearable
          onChange={(newDate) => onChangeParam('departureTimeTo', newDate, false)}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.transportation.placeholderHistoryClearanceLessThan}
          autoComplete="off"
          value={filter.params.clearanceLengthTo}
          active={filter.params.clearanceLengthTo !== ''}
          onChange={(e) => onChangeParam('clearanceLengthTo', e.target.value)}
          size="sm"
        />
      </th>

      <th />

    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  // methods
  onDetail,
}) => {
  if (!data.filterTransportationHistory && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.filterTransportationHistory && data.filterTransportationHistory.rows.length
        }
      />
    );
  }
  if (error || !data.filterTransportationHistory) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterTransportationHistory.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.transportation.historyEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.filterTransportationHistory.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.no}
          </td>
          <td>
            {item.transportationTypeName}
          </td>
          <td>
            {item.reservationTypeId ? (
              <Badge
                style={{ width: 'max-content', }}
                size="lg"
                customColor={getReservationTypeColor(item.reservationTypeId)}
              >
                {item.reservationTypeName}
              </Badge>
            ) : (
              <span>-</span>
            )}
          </td>
          <td>
            {item.truckRegistrationNumber}
          </td>
          <td>
            {Object.prototype.hasOwnProperty.call(
              translations.dropDownOptions.arrivalStateDropDown,
              item.arrivalState
            )
              ? translations.dropDownOptions.arrivalStateDropDown[item.arrivalState]
              : '-'
            }
          </td>
          <td>
            {item.arrivalTime ? parseDateToHuman(new Date(item.arrivalTime)) : '-'}
          </td>
          <td>
            {item.departureTime ? parseDateToHuman(new Date(item.departureTime)) : '-'}
          </td>
          <td>
            {item.clearanceLength ? parseMillisecondsToHumanTime(item.clearanceLength) : '-'}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onDetail(item)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterTransportationHistory: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        no: string,
        transportationTypeName: string,
        truckRegistrationNumber: string,
        arrivalState: string,
        arrivalTime: string,
        departureTime: string,
        reserved: bool,
        clearanceLength: number,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  onDetail: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const HistoryTable = ({
  // data
  translations,
  languageId,
  // methods
  onDetail,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_HISTORY}
    filterToVariables={(filter) => ({
      ...filter,
      languageId: languageId !== null ? parseInt(languageId, 10) : undefined,
      params: {
        ...filter.params,
        transportationType: undefined,
        transportationTypeId: filter.params.transportationType === null
          ? undefined
          : filter.params.transportationType.id,
        reservationType: undefined,
        reservationTypeId: filter.params.reservationType === null
          ? undefined
          : filter.params.reservationType.id,
        arrivalState: filter.params.arrivalState === null
          ? undefined
          : filter.params.arrivalState.id,
        reserved: undefined,
        arrivalTimeFrom: filter.params.arrivalTimeFrom === null
          ? undefined
          : setDateTodayMinTime(filter.params.arrivalTimeFrom),
        arrivalTimeTo: filter.params.arrivalTimeTo === null
          ? undefined
          : setDateTodayMaxTime(filter.params.arrivalTimeTo),
        departureTimeFrom: filter.params.departureTimeFrom === null
          ? undefined
          : setDateTodayMinTime(filter.params.departureTimeFrom),
        departureTimeTo: filter.params.departureTimeTo === null
          ? undefined
          : setDateTodayMaxTime(filter.params.departureTimeTo),
        clearanceLengthFrom: filter.params.clearanceLengthFrom !== ''
          ? parseInt(filter.params.clearanceLengthFrom, 10) * 60 * 1000
          : undefined,
        clearanceLengthTo: filter.params.clearanceLengthTo !== ''
          ? parseInt(filter.params.clearanceLengthTo, 10) * 60 * 1000
          : undefined,
      },
    })}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            languageId={languageId}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            translations={translations}
            // methods
            onDetail={onDetail}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterTransportationHistory
            ? queryData.data.filterTransportationHistory.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />

      </Fragment>
    )}
  </FilteredTable>
);


HistoryTable.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  onDetail: func.isRequired,
};


export default HistoryTable;
