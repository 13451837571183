import React, { Component, } from 'react';
import Select from 'react-select';
import {
  bool, object, string, arrayOf,
} from 'prop-types';
import { withTheme, } from 'styled-components';

import {
  FORM_DEFAULT, FORM_ERROR, FORM_WARNING,
} from '../../globals';


const getColorInput = ({ status, theme, }) => {
  const colors = {
    default: {
      borderColor: theme.grey.t200,
      focusBorderColor: theme.tertiary.t500,
    },
    [FORM_WARNING]: {
      borderColor: theme.warning.t800,
      focusBorderColor: theme.tertiary.t500,

    },
    [FORM_ERROR]: {
      borderColor: theme.error.t800,
      focusBorderColor: theme.tertiary.t500,
    },
  };

  if (Object.prototype.hasOwnProperty.call(colors, status)) {
    return colors[status];
  }
  return colors.default;
};


const getSizeInput = ({ size, }) => {
  const sizes = {
    sm: {
      fontSize: '0.75rem',
    },
    md: {
      fontSize: '0.875rem',
    },
    lg: {
      fontSize: '1rem',
    },
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


class InputSelectStyle extends Component {
  customStyles = {
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    singleValue: (base) => {
      const { theme, } = this.props;
      return {
        ...base,
        color: theme.grey.t700,
      };
    },
    control: (base, state) => {
      const {
        active, theme, size, status,
      } = this.props;
      const colors = getColorInput({ status, theme, });
      const { fontSize, } = getSizeInput({ size, });

      return {
        ...base,
        outline: 'none',
        boxSizing: 'border-box',
        transition: 'all 0.3s ease',
        borderStyle: theme.input.borderStyle,
        fontWeight: 400,
        background: state.isDisabled ? theme.grey.t100 : theme.white,

        minHeight: 'unset',
        padding: '0.5em 0.5em 0.5em 0.9em',
        fontSize,
        lineHeight: 1.5,
        borderColor: (state.isFocused || active) ? colors.focusBorderColor : colors.borderColor,
        boxShadow: 'none',
        fontFamily: theme.input.fontFamily,

        borderRadius: theme.input.borderRadius,
        borderTopWidth: theme.input.borderWidthTopBottom,
        borderBottomWidth: theme.input.borderWidthTopBottom,
        borderRightWidth: theme.input.borderWidthLeftRight,
        borderLeftWidth: theme.input.borderWidthLeftRight,

        '&:hover': {
          borderColor: (state.isFocused || active) ? colors.focusBorderColor : colors.borderColor,
        },
      };
    },

    placeholder: () => {
      const { theme, } = this.props;

      return {
        color: theme.grey.t400,
        opacity: 1,
        maxWidth: 'calc(100% - 8px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      };
    },


    valueContainer: (base, status) => ({
      ...base,
      padding: 0,
      margin: (status.isMulti && status.hasValue)
        ? '-0.125em -0.125em -0.125em -0.4em'
        : '0',
    }),

    multiValue: (base) => {
      const { theme, } = this.props;
      return {
        ...base,
        backgroundColor: theme.tertiary.t700,
        margin: '0.125em',
      };
    },

    multiValueLabel: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        color: theme.white,
        padding: '0 3px',
        fontSize: '1em',
      };
    },

    multiValueRemove: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        cursor: 'pointer',
        color: theme.white,
        borderRadius: `0 ${theme.input.borderRadius} ${theme.input.borderRadius} 0 `,

        '&:hover': {
          background: theme.error.t700,
          color: theme.white,
        },
      };
    },

    clearIndicator: (base, status) => {
      const { theme, } = this.props;

      const color = status.isFocused ? theme.grey.t600 : theme.grey.t400;
      const colorHover = status.isFocused ? theme.error.t800 : theme.error.t600;

      return {
        ...base,
        color,
        cursor: 'pointer',
        padding: 0,
        height: '1.25em',
        width: '1.25em',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
          color: colorHover,
        },
      };
    },


    dropdownIndicator: (base, state) => {
      const { theme, } = this.props;

      const color = state.isFocused ? theme.grey.t600 : theme.grey.t400;
      const colorHover = state.isFocused ? theme.grey.t800 : theme.grey.t600;

      return {
        ...base,
        color,
        cursor: 'pointer',
        padding: 0,
        height: '1.25em',
        width: '1.25em',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
          color: colorHover,
        },
      };
    },


    input: (base) => ({
      ...base,
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
    }),


    indicatorSeparator: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        color: theme.grey.t400,
        display: 'none',
      };
    },


    menu: (base) => {
      const { theme, } = this.props;

      return {
        ...base,
        boxShadow: theme.common.shadow,
        zIndex: 5,
        fontWeight: 400,
      };
    },


    option: (base, { isSelected, isFocused, }) => {
      const { theme, } = this.props;
      let style = {};

      if (isSelected) {
        style = {
          color: theme.white,
          background: theme.tertiary.t500,
        };
      } else if (isFocused) {
        style = {
          background: theme.tertiary.t100,
        };
      }

      return {
        ...base,
        ...style,

        ':active': {
          background: isSelected ? theme.tertiary.t400 : theme.tertiary.t200,
        },
      };
    },
  };


  render() {
    const {
      // data
      options,
      isLoading,
      error,
      loadingMessage,
      noOptionsMessage,
      placeholder,
      errorMessage,
      ...rest
    } = this.props;


    let newPlaceholder = placeholder;
    let newNoOptionsMessage = noOptionsMessage;
    // if error and no data => error message
    if (!isLoading && error) {
      newPlaceholder = errorMessage;
      if (options === null || (options.length && options.length < 1)) {
        newNoOptionsMessage = errorMessage;
      }
    }

    return (
      <Select
        // data
        {...rest}
        styles={this.customStyles}
        options={options}
        onMenuOpen={this.handleFetch}
        isLoading={isLoading}
        placeholder={newPlaceholder}
        classNamePrefix="inputSelect"
        // methods
        loadingMessage={() => loadingMessage}
        noOptionsMessage={() => newNoOptionsMessage}
      />
    );
  }
}


InputSelectStyle.propTypes = {
  // data
  options: arrayOf(object),
  isLoading: bool,
  error: bool,
  shape: string,
  active: bool,
  status: string,
  loadingMessage: string,
  noOptionsMessage: string,
  placeholder: string,
  errorMessage: string,
  theme: object.isRequired,
};


InputSelectStyle.defaultProps = {
  // data
  options: [],
  active: false,
  isLoading: false,
  error: false,
  status: FORM_DEFAULT,
  shape: 'default',
  loadingMessage: '...',
  noOptionsMessage: '',
  placeholder: '',
  errorMessage: 'Error',
};


export default withTheme(InputSelectStyle);
