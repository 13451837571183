import React, { Component, Fragment, } from 'react';
import {
  func, shape, string, object, bool, number,
} from 'prop-types';

import { CLEARANCE_STATES, } from '../../../../globals';
import { MONITORING_COLUMNS, } from '../../pages/MonitoringPage';
import { parseDateToHuman, parseTimeToHuman, } from '../../../../logic/date';
import Badge from '../../../../atoms/Badge/Badge';
import Star from '../../../../styles/icons/app/Star';

import StyledMonitoringItem from './styles/StyledMonitoringItem';


class MonitoringItem extends Component {
  handleDetail = () => {
    const {
      columnData: {
        state,
      },
      data: {
        id,
      },
      onDetail,
    } = this.props;

    onDetail(state, id);
  }


  renderCol2 = () => {
    const {
      columnData,
      data: {
        reservationTypeName,
        reservationTimeFrom,
        reservationTimeTo,
        platformName,
        clearanceState,
        delayed,
      },
      translations,
      translationsCommon,
    } = this.props;

    if (delayed
      && (
        columnData.state === MONITORING_COLUMNS[0].state
        || columnData.state === MONITORING_COLUMNS[1].state
      )
    ) {
      return (
        <Badge color="error">
          {translations.monitoring.statusDelayed}
        </Badge>
      );
    }

    if (columnData.state === MONITORING_COLUMNS[2].state
      && (
        clearanceState === CLEARANCE_STATES.DELAYED
        || clearanceState === CLEARANCE_STATES.FORWARDED
      )
    ) {
      return (
        <Badge>
          {translationsCommon.storekeeperClearingStates[clearanceState]}
        </Badge>
      );
    }

    return (
      <>
        <div className="monitoring--item-col-text">
          {reservationTypeName}
        </div>
        {(reservationTimeFrom && reservationTimeTo) && (
          <div className="monitoring--item-col-text">
            {`${parseTimeToHuman(new Date(reservationTimeFrom))} - ${parseTimeToHuman(new Date(reservationTimeTo))}`}
          </div>
        )}
        <div className="monitoring--item-col-text">
          {platformName}
        </div>
      </>
    );
  }


  renderDate = () => {
    const {
      data: {
        date,
        reservationTimeFrom,
        reservationTimeTo,
      },
    } = this.props;

    if (!reservationTimeFrom || !reservationTimeTo) {
      return (
        <Fragment>
          <span className="monitoring--item-col-time-date">
            {parseDateToHuman(new Date(date), false)}
          </span>
          {/*
            <span className="monitoring--item-col-time-time">
              {parseTimeToHuman(new Date(date))}
            </span>
          */}
        </Fragment>
      );
    }

    const fromDate = reservationTimeFrom ? parseDateToHuman(new Date(reservationTimeFrom), false) : '-';
    const fromTime = reservationTimeFrom ? parseTimeToHuman(new Date(reservationTimeFrom)) : '-';
    const toTime = reservationTimeTo ? parseTimeToHuman(new Date(reservationTimeTo)) : '-';

    return (
      <Fragment>
        <span className="monitoring--item-col-time-date">
          {fromDate}
        </span>
        <span className="monitoring--item-col-time-time">
          {`${fromTime} - ${toTime}`}
        </span>
      </Fragment>
    );
  }


  render() {
    const {
      data: {
        no,
        transportationTypeName,
        truckRegistration,
        priorityLevel,
      },
    } = this.props;

    return (
      <StyledMonitoringItem
        className="monitoring--item"
        onClick={this.handleDetail}
      >
        <div className="monitoring--item-col1">
          <div className="monitoring--item-col-text monitoring--item-col-no">
            <span style={{ paddingRight: '2px', }}>
              { priorityLevel === 2 && (
                <Star color="#ffb322" />
              )}
              { priorityLevel === 3 && (
                <Star color="#ff483f" />
              )}
            </span>
            {no}
          </div>
          <div className="monitoring--item-col-text monitoring--item-col-truckNo">
            {truckRegistration}
          </div>
          <div className="monitoring--item-col-text monitoring--item-col-type">
            {transportationTypeName}
          </div>
        </div>
        <div className="monitoring--item-col2">
          {this.renderCol2()}
        </div>

      </StyledMonitoringItem>
    );
  }
}


MonitoringItem.propTypes = {
  columnData: shape({
    state: string.isRequired,
  }).isRequired,
  data: shape({
    id: string.isRequired,
    no: string.isRequired,
    transportationTypeName: string.isRequired,
    truckRegistration: string.isRequired,
    reservationTypeName: string.isRequired,
    reservationTimeFrom: string,
    reservationTimeTo: string,
    delayed: bool.isRequired,
    platformName: string,
    clearanceState: string,
    priorityLevel: number.isRequired,
  }).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  onDetail: func.isRequired,
};


export default MonitoringItem;
