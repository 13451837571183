import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import { pipe, } from '../../../../../logic/utils';
import { withErrors, } from '../../../../../logic/errorManager/withErrors';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
} from '../../../../../logic/form/common';
import { callFormStructure, } from './form';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalCallView from './ModalCallView';


class ModalCall extends Component {
  constructor(props) {
    super(props);

    const {
      transportation: {
        platformName,
        platformId,
      },
    } = props;

    const showForm = (!platformId || !platformName);

    this.state = {
      showForm,
      callForm: {
        ...callFormStructure,
        values: {
          platform: showForm
            ? null
            : {
              id: platformId,
              name: platformName,
            },
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleChangeForm = (name, value) => {
    const { callForm, } = this.state;

    this.setState({
      callForm: changeAndValidateInput(callForm, name, value),
    });
  }


  /**
   * Call Transportation
   */
  handleCallTransportation = (mutationCall) => {
    const { callForm: { values, }, } = this.state;
    const { transportation, } = this.props;

    mutationCall({
      variables: {
        platformId: values.platform ? values.platform.id : null,
        transportationId: transportation.id,
      },
    });
  }

  /**
   * Call Transportation - Completed
   */
  handleCallTransportationCompleted = () => {
    const { translations, onToggle, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleCallSuccess,
    });

    onToggle();
  }

  /**
   * Call Transportation - Error
   */
  handleCallTransportationError = (graphQLError) => {
    const { translations, addNotification, translateGraphQLError, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleCallError,
      text: translateGraphQLError(graphQLError),
    });
  }


  render() {
    const { showForm, callForm, } = this.state;
    const {
      // data
      transportation,
      languageId,
      translations,
      // methods
      onToggle,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.modalCallTitle}
        disablePadding
      >
        <ModalCallView
          // data
          showForm={showForm}
          callForm={callForm}
          transportation={transportation}
          languageId={languageId}
          translations={translations}
          // functions
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onCallTransportation={this.handleCallTransportation}
          onCallTransportationCompleted={this.handleCallTransportationCompleted}
          onCallTransportationError={this.handleCallTransportationError}
        />
      </Modal>
    );
  }
}


ModalCall.propTypes = {
  // data
  transportation: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  translateGraphQLError: func.isRequired,
};


export default pipe(
  withErrors,
  withNotifications,
)(ModalCall);
