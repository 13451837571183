import { INPUT_TYPE, } from 'sofair-form-validation';

/**
 * App template
 */
export const SIDEBAR_WIDTH = 235; // px
export const HEADER_HEIGHT = 3.5; // rem


/**
 * Input State
 */
export const FORM_DEFAULT = 'FORM_DEFAULT';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_WARNING = 'FORM_WARNING';


/**
 * Grid
 */
export const GRID_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};


/**
 * GraphQL Errors
 */
export const GraphQLErrorsGlobal = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
  SETTINGS_ROLE_USED: 'SETTINGS_ROLE_USED',
  UNABLE_TO_START: 'UNABLE_TO_START',
};


/**
 * Messages
 */
export const PARAM_PREFIX = '#*@';
export const PARAM_POSTFIX = '@*#';
export const MESSAGES = {
  // common
  SOMETHING_HAPPENED: 'SOMETHING_HAPPENED',
  // errors
  NOT_CONNECTED: 'NOT_CONNECTED',
  INVALID_FORM: 'INVALID_FORM',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
  // sofair-form-validation
  INPUT_TEXT_REQUIRED: 'INPUT_TEXT_REQUIRED',
  INPUT_TEXT_MIN: 'INPUT_TEXT_MIN',
  INPUT_TEXT_MAX: 'INPUT_TEXT_MAX',
  INPUT_NUMBER_REQUIRED: 'INPUT_NUMBER_REQUIRED',
  INPUT_NUMBER_MIN: 'INPUT_NUMBER_MIN',
  INPUT_NUMBER_MAX: 'INPUT_NUMBER_MAX',
  INPUT_SELECT_REQUIRED: 'INPUT_SELECT_REQUIRED',
  INPUT_MULTISELECT_REQUIRED: 'INPUT_MULTISELECT_REQUIRED',
  INPUT_MULTISELECT_MIN: 'INPUT_MULTISELECT_MIN',
  INPUT_MULTISELECT_MAX: 'INPUT_MULTISELECT_MAX',
  INPUT_CHECKBOX_REQUIRED: 'INPUT_CHECKBOX_REQUIRED',
  INPUT_RADIO_REQUIRED: 'INPUT_RADIO_REQUIRED',
  REGEX_EMAIL: 'REGEX_EMAIL',
  REGEX_PHONE_NUMBER: 'REGEX_PHONE_NUMBER',
  REGEX_FLOAT: 'REGEX_FLOAT',
  // STAND ACTIONS
  UNABLE_TO_START: 'UNABLE_TO_START',
};


/**
 * Notifications
 */
export const NOTIFICATIONS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};


/**
 * Order
 */
export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};


/**
 * Generic Form
 */
export const FORM_TYPES = {
  TEXT: INPUT_TYPE.TEXT,
  NUMBER: INPUT_TYPE.NUMBER,
  SELECT: INPUT_TYPE.SELECT,
  MULTISELECT: INPUT_TYPE.MULTISELECT,
  CHECKBOX: INPUT_TYPE.CHECKBOX,
  RADIO: INPUT_TYPE.RADIO,
  TIME: 'time',
  DATE: 'date',
  TIMESTAMP: 'timestamp',
  FIELDSET: 'fieldset',
  GROUP: 'group',
};

export const FORM_ROOT = 'formRoot';


/**
 * Transportation Reservation Types
 */
export const RESERVATION_TYPES = {
  DAY: '1',
  INTERVAL: '2',
  INTERVAL_PLATFORM: '3',
};


export const CLEARANCE_STATES = {
  CALLED: 'called',
  CLEARING: 'clearing',
  INTERRUPTED: 'interrupted',
  SOLVING: 'solving',
  DELAYED: 'delayed',
  FORWARDED: 'forwarded',
};
