import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import { QUERY_TRUCKS, } from '../../gql/queries';
import { MUTATION_DELETE_TRUCK, } from '../../gql/mutations';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import TruckCreate from '../TruckCreate/TruckCreate';
import TruckUpdate from '../TruckUpdate/TruckUpdate';
import ModalDelete from '../../../../components/modal/ModalDelete';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';


const COL_COUNT = 4;
const INIT_FILTER = {
  offset: 0,
  limit: 25,
  sortBy: '',
  order: '',
  params: {
    id: '',
    registrationNumber: '',
    vehicleGroupName: '',
  },
};


const Header = ({
  // data
  filter,
  translations,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <THSortable
        title="#"
        name="id"
        isActiveFilter={filter.params.id !== ''}
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.truckRegistrationNumber}
        name="registrationNumber"
        isActiveFilter={filter.params.registrationNumber !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '15rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.vehicleGroupName}
        name="vehicleGroupName"
        isActiveFilter={filter.params.vehicleGroupName !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '15rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--text-align-right"
        style={{ minWidth: '10rem', width: '10rem', }}
      >
        {translations.directory.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.id}
          active={filter.params.id !== ''}
          onChange={(e) => onChangeParam('id', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.registrationNumber}
          active={filter.params.registrationNumber !== ''}
          onChange={(e) => onChangeParam('registrationNumber', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.vehicleGroupName}
          active={filter.params.vehicleGroupName !== ''}
          onChange={(e) => onChangeParam('vehicleGroupName', e.target.value)}
          size="sm"
        />
      </th>

      <th />
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  resources,
  translations,
  // methods
  onToggleUpdate,
  onToggleDelete,
}) => {
  if (!data.filterTrucks && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={data && data.filterTrucks && data.filterTrucks.rows.length}
      />
    );
  }
  if (error || !data.filterTrucks) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterTrucks.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.directory.infoTrucksEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.filterTrucks.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.registrationNumber}
          </td>
          <td>
            {item.vehicleGroupName}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={(e) => onToggleUpdate(e, item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    disabled={!resources.directory_truck_write}
                    onClick={(e) => onToggleDelete(e, item.id)}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterTrucks: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        name: string,
        phoneNumber: string,
      })).isRequired,
    }),
  }),
  resources: object.isRequired,
  translations: object.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const TrucksTable = ({
  // data
  resources,
  languageId,
  createModal,
  updateModal,
  deleteModal,
  translations,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_TRUCKS}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>

        {createModal.isOpen && (
          <TruckCreate
            modalData={createModal}
            languageId={languageId}
            onToggle={onToggleCreate}
            onCreated={() => queryData.refetch()}
          />
        )}

        {updateModal.isOpen && (
          <TruckUpdate
            modalData={updateModal}
            resources={resources}
            languageId={languageId}
            onToggle={onToggleUpdate}
            onUpdated={() => queryData.refetch()}
          />
        )}

        {deleteModal.isOpen && (
          <ModalDelete
            // data
            title={translations.directory.titleDeleteModalTruck}
            text={translations.directory.textDeleteModalTruck}
            mutation={MUTATION_DELETE_TRUCK}
            variables={{ id: deleteModal.id, }}
            // methods
            onToggle={onToggleDelete}
            onCompleted={queryData.refetch}
          />
        )}

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            resources={resources}
            translations={translations}
            // methods
            onToggleUpdate={onToggleUpdate}
            onToggleDelete={onToggleDelete}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterTrucks
            ? queryData.data.filterTrucks.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />

      </Fragment>
    )}
  </FilteredTable>
);


TrucksTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  deleteModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default TrucksTable;
