import React from 'react';
import styled from 'styled-components';
import { string, bool, } from 'prop-types';


const StyledLabel = styled.label`
  padding-left: 3px;
  font-weight: 500;
  color: ${(p) => p.theme.grey.t700};
`;

const Label = ({ label, isRequired, }) => ((label === '')
  ? null
  : (
    <StyledLabel>
      {`${label}${isRequired ? ' *' : ''}`}
    </StyledLabel>
  )
);


Label.propTypes = {
  label: string,
  isRequired: bool,
};


Label.defaultProps = {
  label: '',
  isRequired: false,
};


export default Label;
