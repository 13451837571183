import styled from 'styled-components';


const StyledMonitoring = styled.div`
  display: flex;


  .monitoring--content {
    display: flex;
  }

  .monitoring--content-list {
    padding: 0;
    margin: 0 -0.5rem;
    display: flex;
    flex-direction: row;
  }

  /* COLUMN */
  .monitoring--column {
    list-style: none;
    width: 20rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    box-sizing: border-box;
    padding: ${(p) => p.theme.common.paddingHalf};
    background: ${(p) => p.theme.white};
  }

  .monitoring--col-title {
    text-align: center;
    border-bottom: 1px solid ${(p) => p.theme.grey.t300};
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  /* ITEMS */
  .monitoring--items {
    margin: 0;
    padding: 0;
  }

  .monitoring--items li:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .monitoring--item {
    list-style: none;
  }
`;

export default StyledMonitoring;
