import React from 'react';
import styled from 'styled-components';
import {
  string, arrayOf, oneOfType, node,
} from 'prop-types';


const Label = styled.span`
  font-size: 0.9em;
  font-weight: 500;
  color: ${(p) => p.theme.grey.t600};
`;


const FormRowReadOnly = ({ label, children, }) => (
  <div style={{ marginBottom: '0.5rem', }}>
    <Label>
      {label}
    </Label>
    <div>
      {children}
    </div>
  </div>
);

FormRowReadOnly.propTypes = {
  label: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

FormRowReadOnly.defaultProps = {
  label: null,
};


export default FormRowReadOnly;
