import styled from 'styled-components';


const StyledMonitoringItem = styled.li`
  display: flex;
  border: 1px solid ${(p) => p.theme.grey.t200};
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
  height: 5rem;
  padding: 0 0.5rem;
  background: ${(p) => p.theme.grey.t50};
  cursor: pointer;

  &:hover {

  }


  .monitoring--item-col1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
  }

  .monitoring--item-col2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: center;
    color: ${(p) => p.theme.grey.t700};
    text-align: right;
  }

  .monitoring--item-col-text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }


  .monitoring--item-col-no {
    font-weight: bold;
  }

  .monitoring--item-col-truckNo {

  }

  .monitoring--item-col-type {

  }


  .monitoring--item-col-state {

  }

  .monitoring--item-col-time {

  }

  .monitoring--item-col-time-date {
    font-weight: 500;
  }

  .monitoring--item-col-time-time {
    margin-left: 0.5rem;
  }
`;


export default StyledMonitoringItem;
