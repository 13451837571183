import gql from 'graphql-tag';


/**
 * Utils
 */
const translations = `
  dictionary {
    centralApp {
      errors {
        CUSTOM_ERROR_FROM_GREATER_THAN_TO
        REGEX_EMAIL
        REGEX_PHONE_NUMBER
        REGEX_FLOAT
        RESERVATION_FULL
        NEXT_STAND_CAPACITY_FULL
        INPUT_UNIQUE
        BAD_CREDENTIALS
        INPUT_TEXT_REQUIRED
        INPUT_TEXT_MIN
        INPUT_TEXT_MAX
        INPUT_NUMBER_REQUIRED
        INPUT_NUMBER_MIN
        INPUT_NUMBER_MAX
        INPUT_SELECT_REQUIRED
        INPUT_MULTISELECT_REQUIRED
        INPUT_MULTISELECT_MIN
        INPUT_MULTISELECT_MAX
        INPUT_CHECKBOX_REQUIRED
        INPUT_RADIO_REQUIRED
        NOT_CONNECTED
        INVALID_FORM
        SOMETHING_HAPPENED
        USER_BLOCKED
        UNAUTHORIZED
        STAND_NO_CONFIGURATION
        NO_ALREADY_USED
        IDENTIFICATION_NUMBER_ALREADY_USED
        EMPTY_QUEUE
        NEXT_STAND_FULL
        BAD_PLATFORM
        PLATFORM_FULL_CAPACITY
        UNABLE_TO_START
      }
      common {
        appTitle
        menu
        dashboard
        transportation
        schedule
        history
        directory
        companies
        drivers
        trucks
        vehicleGroups
        create
        update
        delete
        back
        filter
        created
        deleted
        updated
        loading
        noOptions
        from
        to
        select
        unit
        confirm
        automatic
        manual
        yes
        no
        departed
        close
        stands
        monitoringModule
        monitoring
        notifications
        type
        time
        settings
        userSettings
        detail
        home
        greaterThan
        lessThan
        storekeeper
        btnSave
        pictureLabelParkIn
      }
      dashboard {
        planText
        monitoringText
        standText
        clearanceText
      }
      settings {
        rolesTitle
        createRole
        usersTitle
        createUser
        createRoleTitle
        removeRoleTitle
        removeRoleText
        removeRoleForceTitle
        removeRoleForceText
        removeRoleForce
        createUserTitle
        removeUserTitle
        removeUserText
        detailRoleTitle
        detailUserTitle
        userUnlocked
        userBlocked
        allResources
        block
        unblock
        thRoles
        thBlocked
        thUsername
        thActions
        thName
        removeRoleForceUsersHeader
        usersEmpty
        rolesEmpty
      }
      directory {
        createModalCompanyTitle
        updateModalCompanyTitle
        deleteModalCompanyTitle
        deleteCompanyModalText
        newCompany
        newDriver
        createModalDriverTitle
        updateModalDriverTitle
        deleteModalDriverTitle
        deleteModalDriverText
        subTitleContactInformation
        subTitleBillingInformation
        driversEmpty
        companiesEmpty
        btnNewTruck
        titleDeleteModalTruck
        textDeleteModalTruck
        infoTrucksEmpty
        thActions
        titleCreateModalTruck
        titleUpdateModalTruck
        btnNewGroup
        titleCreateModalVehicleGroup
        titleUpdateModalVehicleGroup
        titleDeleteModalVehicleGroup
        textDeleteModalVehicleGroup
        infoVehicleGroupsEmpty
      }
      transportation {
        createModalTransportationTitle
        subHeaderReservation
        subHeaderVehicle
        subHeaderCargo
        infoNotSelectedReservationType
        newTransportation
        deleteModalDriverTitle
        deleteModalDriverText
        historyEmpty
        thActions
        scheduleEmpty
        titleScheduleTimelineLegend
        tabScheduleAll
        tabScheduleTimelinePlatforms
        tabScheduleTimelineTransportationTypes
        tabScheduleTableReservationDay
        btnNewTransportation
        btnApplyFilter
        labelRange
        range6Hours
        range12Hours
        range18Hours
        range24Hours
        range30Hours
        headerTimelinePlatforms
        headerTimelineTransportationType
        editModalTransportationTitle
        titleTransportationFlow
        placeholderTransportationType
        placeholderPlatform
        transportationNotStartedYet
        titleTransportationActions
        titleDeleteTransportation
        textDeleteTransportation
        placeholderFilterCompany
        placeholderFilterDriver
        placeholderFilterTruck
        btnApplyFilter
        btnSaveAsNewCompany
        titleReservationForm
        titleCompanyForm
        titleDriverForm
        titleTruckForm
        titleTrailerForm
        otherForm
        placeholderHistoryClearanceGreaterThan
        placeholderHistoryClearanceLessThan
        labelReservationOnDayAproxArrivalTime
        connectedTransports {
          titleSettings
          labelAutostartAfterFinishPrev
          buttonAddNext
          titleDetail
          titlePrevTransport
          titleNextTransport
        }
        priority
      }
      dropDownOptions {
        arrivalStateDropDown {
          planned
          delayed
          premature
          notComing
          ok
          inactive
        }
        yesNoAllDropDown {
          yes
          no
          all
        }
        transportationReservationTypeDropDown {
          registration
          reservationTimeWindow
          reservationTimeWindowWithPlatform
        }
      }
      form {
        personName
        username
        password
        name
        email
        phoneNumber
        identificationNumber
        vatNumber
        street
        city
        zip
        state
        surname
        carrier
        driver
        recipients
        transportationType
        truckRegistrationNo
        trailerRegistrationNo
        cargoCode
        cargoWeight
        cargoWeightUnit
        cargoHeight
        cargoHeightUnit
        cargoLength
        cargoLengthUnit
        cargoPalettesCount
        reservationType
        date
        platform
        info
        contactPerson
        no
        arrivalState
        arrivalTime
        departureTime
        reserved
        clearanceTime
        roles
        blocked
        pin
        chip
        newPassword
        reservationNumber
        timeWindow
        clearanceLength
        truckRegistrationNumber
        vehicleGroupName
        isInternal
        freeEntrance
        freeExit
        isInternalText
        freeEntranceText
        freeExitText
        carType
        smsText
        sendSms
        sentSucceded
        language
        supplierNumber
        priority
        note
      }
      authentication {
        login
        logout
        loginInfoTitle
        loginInfoText
      }
      notFound {
        infoTitle
        infoText
        linkText
      }
      notAuthorized {
        infoTitle
        infoText
        linkText
      }
      stand {
        actionWizardTitle
        actionWizardTabFinish
        transportWasMoved
        modalSaveChangesTitle
        modalSaveChangesText
        selectStandPlaceholder
        automaticSelectText
        moveToNextStand
        standHeader
        noStandsText
        confirmStandWizardModalTitle
        confirmStandWizardModalText
        labelSelectStand
        standTableNoData
        thActions
        btnTransportationActionActive
        btnTransportationActionInactive
        btnVisitorAction
        labelMoveToNextStand
        thActions
        errorStandMissingTab
        textTabActionNoForm
        doCall
        undoCall
        repeatCall
        btnCreateTransportationAndRegistration
        callStates {
          waiting
          called
          clearing
        }
        messageTitleCalled
        messageTitleCallCanceled
      }
      transportation {
        createTransportation
        detailTransportationModalTitle
      }
      visitor {
        createVisitor
        createModalVisitorTitle
        visitorDepartureModalTitle
        visitorDepartureModalText
      }
      monitoring {
        notificationTypes {
          delayedTransportationArrival
          delayedTransportationClearance
          transportationStuck
          help
          printerEmptyPaperError
          printerOpenCaseError
          printerAlmostEmptyPaperError
          printerCutterError
        }
        stand
        transportation
        plan
        register
        waiting
        clearing
        cleared
        finished
        modalRemoveNotificationTitle
        modalRemoveNotificationText
        titleNotifications
        other
        thType
        thTransportationType
        thTime
        thActions
        thInfo
        notificationsEmpty
        statusDelayed
        statusOk
      }
      centralAppStorekeeper {
        tabAllPlatforms
        tabPlatform
        placeholderQueuePlatform
        infoQueueSelectPlatform
        infoQueueNoTransportation
        btnQueueCall
        btnQueueDetail
        notifTitleCallSuccess
        notifTitleCallError
        modalCallTitle
        placeholderModalCallPlatform
        notifTitleSetPlatformOpenSuccess
        notifTitleSetPlatformOpenError
        notifTitleSetPlatformCloseSuccess
        notifTitleSetPlatformCloseError
        btnOpenPlatform
        btnClosePlatform
        platformCapacity
        infoPlatformClosed
        btnPlatformCall
        btnPlatformCallQueue
        notifTitleClearedSuccess
        notifTitleClearedError
        notifTitleRepeatCallSuccess
        notifTitleRepeatCallError
        notifTitleCancelSuccess
        notifTitleCancelError
        notifTitleContinueSuccess
        notifTitleContinueError
        notifTitleFinishSuccess
        notifTitleFinishError
        btnTransportationClear
        btnTransportationRepeatCall
        btnTransportationCancel
        btnTransportationForward
        btnTransportationDelay
        btnTransportationForm
        btnTransportationFinish
        timeStarted
        titleModalInterrupt
        btnInterruptParking
        btnInterruptLong
        titleModalForward
        btnForward
        placeholderModalForwardPlatform
        titleModalFinish
        notifTitleInterruptSuccess
        notifTitleInterruptError
        notifTitleDelaySuccess
        notifTitleDelayError
        notifTitleForwardSuccess
        notifTitleForwardError
        btnFinish
        labelSolve
        textSolve
        labelManual
        textManual
        placeholderModalFinishPlatforms
        titleModalDetail
        notifTitleFormSaved
        platformFreeSpace
        btnTransportationContinue
        textModalForward
        titleQueue
      }
    }
    shared {
      storekeeperClearingStates {
        called
        clearing
        interrupted
        solving
        delayed
        forwarded
      }
      calendar {
        dayShortSu
        dayShortMo
        dayShortTu
        dayShortWe
        dayShortTh
        dayShortFr
        dayShortSa
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
        nextMonth
        prevMonth
      }
    }
  }
`;


/**
 * Main
 */
export const QUERY_INIT = gql`
  query Init($languageId: ID) {
    dictionary(languageId: $languageId) {
      language {
        id
        name
        code
      }
      ${translations}
    }
    languages {
      centralAppLangs {
        id
        name
        code
      }
    }
  }
`;

export const QUERY_DICTIONARY = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      language {
        id
        name
        code
      }
      ${translations}
    }
  }
`;

export const QUERY_LANGUAGES = gql`
  query Languages {
    languages {
      centralAppLangs {
        id
        name
        code
      }
    }
  }
`;


/**
 * Common
 */
export const APP_LANGUAGE_ID = gql`
  {
    languageId @client
  }
`;


/**
 * User
 */
export const QUERY_USER = gql`
  query GetUser($accessToken: String!) {
    getUser(accessToken: $accessToken) {
      user {
        id
        username
      }
      resources
    }
  }
`;
