import React, { Component, } from 'react';
import { string, shape, } from 'prop-types';

import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import Collapse from '../../../../atoms/Collapse/Collapse';
import FormBackground from '../../../../components/Form/FormBackground';
import FlowChartGenForm from './FlowChartGenForm';

import Arrow from '../../../../styles/icons/Arrow';


class FlowChartAction extends Component {
  state = {
    isOpen: false,
  };


  handleToggle = () => {
    const {
      data: { genericFormId, },
    } = this.props;

    if (!genericFormId) this.setState({ isOpen: false, });
    else this.setState((prevState) => ({ isOpen: !prevState.isOpen, }));
  }


  render() {
    const { isOpen, } = this.state;
    const {
      data: {
        title,
        genericFormId,
        actionId,
        start,
      },
      transportationId,
      languageId,
    } = this.props;

    if (!genericFormId) {
      return (
        <h5>
          {title}
        </h5>
      );
    }

    return (
      <>
        <h5>
          {title}
          <ButtonIcon
            className="transpDetail--flow-right-toggle"
            onClick={this.handleToggle}
            size="sm"
            thin
            color="tertiary"
            disabled={!start}
          >
            <Arrow className={`transpDetail--flow-right-icon ${isOpen ? ' transpDetail--flow-right-icon-open' : 'transpDetail--flow-right-icon-close'}`} />
          </ButtonIcon>
        </h5>
        <Collapse isOpen={isOpen}>
          {isOpen && (
            <FormBackground>
              <FlowChartGenForm
                genericFormId={genericFormId}
                actionId={actionId}
                transportationId={transportationId}
                languageId={languageId}
              />
            </FormBackground>
          )}
        </Collapse>
      </>
    );
  }
}


FlowChartAction.propTypes = {
  data: shape({
    actionId: string.isRequired,
    genericFormId: string,
    title: string.isRequired,
    start: string,
  }).isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
};


export default FlowChartAction;
