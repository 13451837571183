import React from 'react';
import { object, } from 'prop-types';
import { Route, Switch, } from 'react-router-dom';
import { Query, withApollo, } from 'react-apollo';

import { QUERY_INIT, APP_LANGUAGE_ID, } from '../gql/queries';
import { getLanguageId, } from '../../../logic/localStorage/auth';
import ErrorManagerProvider from '../../../logic/errorManager/ErrorManagerProvider';
import NotificationsProvider from '../../../logic/notifications/NotificationsProvider';
import ModulesLayout from './ModulesLayout';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import LoginPage from './LoginPage';

import StyledApp from '../../../styles/StyledApp';


const MainLayout = ({ client, }) => (
  <Query query={APP_LANGUAGE_ID}>
    {({ data: { languageId, }, }) => (
      <Query
        query={QUERY_INIT}
        variables={{
          // HOTFIX - languageId should be persisted in cache
          languageId: (languageId === undefined || languageId === null)
            ? getLanguageId()
            : languageId,
        }}
        fetchPolicy={languageId !== null ? 'cache-first' : 'no-cache'}
        onCompleted={(response) => {
          client.writeQuery({
            query: QUERY_INIT,
            variables: {
              languageId: response.dictionary.language.id,
            },
            data: response,
          });
          client.writeData({ data: { languageId: response.dictionary.language.id, }, });
        }}
      >
        {({ loading, error, data, }) => {
          if (loading) return <LoadingPage />;

          if (
            error
            || !data.dictionary
            || !data.languages
          ) return <ErrorPage />;

          return (
            <StyledApp>
              <ErrorManagerProvider>
                <NotificationsProvider>
                  <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route path="/" component={ModulesLayout} />
                  </Switch>
                </NotificationsProvider>
              </ErrorManagerProvider>
            </StyledApp>
          );
        }}
      </Query>
    )}
  </Query>
);


MainLayout.propTypes = {
  client: object.isRequired,
};


export default withApollo(MainLayout);
