import { INPUT_TYPE, } from 'sofair-form-validation';


import {
  FORM_DEFAULT, FORM_ERROR, FORM_SUCCESS,
  MESSAGES,
} from '../../globals';
import { parseMessage, } from '../messages/messages';
import { validateInput, validateWholeForm, } from './validation';
import { mergeFormValidation, } from './utils';


export const initForm = {
  isValid: true,
  invalidInputs: 0,
  form: {},
  values: {},
  message: parseMessage(),
};


export const initFormValidation = {
  required: false,
  min: null,
  max: null,
  regex: null,
};


export const initInput = ({
  name = 'name',
  type = INPUT_TYPE.TEXT,
  validation = initFormValidation,
  status = FORM_DEFAULT,
  statusMsg,
  ...extraProps
}) => ({
  name,
  type,
  validation: {
    ...initFormValidation,
    ...validation,
  },
  status,
  statusMsg: parseMessage(statusMsg),
  ...extraProps,
});


export const changeFormValidations = (inputs, newValidationArray = []) => {
  const changedForms = {};
  let newInvalidInputs = inputs.invalidInputs;

  for (let i = 0; i < newValidationArray.length; i++) {
    const { name, validation, } = newValidationArray[i];
    changedForms[name] = initInput({
      ...inputs.form[name],
      validation,
      status: undefined, // reset
      statusMsg: undefined, // reset
    });

    if (inputs.form[name].status === FORM_ERROR) {
      newInvalidInputs--;
    }
  }

  return {
    ...inputs,
    isValid: newInvalidInputs < 1,
    invalidInputs: newInvalidInputs,
    form: {
      ...inputs.form,
      ...changedForms,
    },
  };
};


/**
 * Validate input and return all state
 */
export const changeAndValidateInput = (inputs, name, value) => {
  try {
    const { form, values, invalidInputs, } = inputs;

    const validation = validateInput(form[name], value);
    const isValid = !Object.prototype.hasOwnProperty.call(validation, name);

    // set helpers
    const newStatus = isValid ? FORM_SUCCESS : FORM_ERROR;
    const newStatusMsg = isValid
      ? parseMessage()
      : validation[name];

    let newInvalidInputs = invalidInputs;
    if (
      (newStatus === FORM_ERROR && form[name].status !== FORM_ERROR)
      || (newStatus !== FORM_ERROR && form[name].status === FORM_ERROR)
    ) {
      if (newStatus === FORM_ERROR) newInvalidInputs++;
      else newInvalidInputs--;
    }

    // build new form
    return {
      ...inputs,
      isValid: newInvalidInputs < 1,
      invalidInputs: newInvalidInputs,
      customError: null,
      form: {
        ...form,
        [name]: {
          ...form[name],
          status: newStatus,
          statusMsg: newStatusMsg,
        },
      },
      values: {
        ...values,
        [name]: value,
      },
      // reset message
      message: parseMessage(),
    };
  } catch (error) {
    return {
      ...inputs,
      message: parseMessage({ code: MESSAGES.SOMETHING_HAPPENED, }),
    };
  }
};


export const changeMultipleValues = (inputs, array) => {
  let ret = inputs;

  for (let i = 0; i < array.length; i++) {
    const { name, value, } = array[i];

    // TODO remake
    ret = changeAndValidateInput(ret, name, value);
  }

  return ret;
};


/**
 * Validate Whole Form
 */
export const validateAndMergeWholeForm = (data, customValidation) => {
  try {
    const { form, values, } = data;

    const validation = validateWholeForm(form, values);

    const merged = mergeFormValidation(form, validation);
    let customError = null;
    if (merged.invalidInputs < 1 && customValidation) {
      customError = customValidation(form, values);
    }

    return {
      ...data,
      isValid: merged.invalidInputs < 1 && !customError,
      invalidInputs: merged.invalidInputs,
      customError,
      form: merged.form,
      message: parseMessage(),
    };
  } catch (error) {
    return {
      ...data,
      message: parseMessage({ code: MESSAGES.SOMETHING_HAPPENED, }),
    };
  }
};


/**
 * Merge validation object into state
 */
export const mergeValidationObjectIntoForm = (data, validation) => {
  try {
    const { form, } = data;

    if (Object.keys(validation).length < 1) {
      return data;
    }
    const merged = mergeFormValidation(form, validation);

    return {
      ...data,
      isValid: merged.invalidInputs < 1,
      invalidInputs: merged.invalidInputs,
      customError: null,
      form: merged.form,
      message: parseMessage(),
    };
  } catch (error) {
    return {
      ...data,
      message: parseMessage({ code: MESSAGES.SOMETHING_HAPPENED, }),
    };
  }
};
