import gql from 'graphql-tag';


const getColumns = (columns) => {
  let params = '';
  for (let i = 0; i < columns.length; i++) {
    params += `${columns[i]}
    `;
  }
  return params;
};


export const SUBSCRIPTION_TRANSPORTATION_TABLE_DATA = (columns) => {
  const params = getColumns(columns);
  const query = `
    subscription StandDashboardTransportationData($languageId: ID, $standId: ID, $type: String, $transportationTypeIds: [ID]) {
      standDashboardTransportationData(languageId: $languageId, standId: $standId, type: $type, transportationTypeIds: $transportationTypeIds) {
        rows {
          id
          active
          ${params}
        }
      }
    }
  `;
  return gql(query);
};


export const SUBSCRIPTION_VISITORS = (columns) => {
  const params = getColumns(columns);
  const query = `
  subscription StandDashboardVisitorsData {
    standDashboardVisitorsData {
        rows {
          id
          ${params}
        }
      }
    }
  `;
  return gql(query);
};
