import React from 'react';
import { func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import {
  MUTATION_INTERRUPT_TRANSPORTATION,
  MUTATION_DELAY_TRANSPORTATION,
} from '../../../gql/mutations';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import FormBackground from '../../../../../components/Form/FormBackground';

import StyledModalInterrupt from './styles/StyledModalInterrupt';


const TransportationInterruptModalView = ({
  // data
  translations,
  // methods
  onToggle,
  // delay
  onDelayTransportation,
  onDelayTransportationCompleted,
  onDelayTransportationError,
  // interrupt
  onInterruptTransportation,
  onInterruptTransportationCompleted,
  onInterruptTransportationError,
}) => (
  <StyledModalInterrupt>
    <FormBackground>

      <Row formGroup>
        <Col textAlign="center">
          <Mutation
            mutation={MUTATION_DELAY_TRANSPORTATION}
            onCompleted={onDelayTransportationCompleted}
            onError={onDelayTransportationError}
          >
            {(mutationDelay, { loading, }) => (
              <ButtonLoader
                className="storekeeperModalInterrupt--btn"
                color="primary"
                onClick={() => onDelayTransportation(mutationDelay)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnInterruptParking}
              </ButtonLoader>
            )}
          </Mutation>
        </Col>
      </Row>
      <Row formGroup>
        <Col textAlign="center">
          <Mutation
            mutation={MUTATION_INTERRUPT_TRANSPORTATION}
            onCompleted={onInterruptTransportationCompleted}
            onError={onInterruptTransportationError}
          >
            {(mutationInterrupt, { loading, }) => (
              <ButtonLoader
                className="storekeeperModalInterrupt--btn"
                color="primary"
                onClick={() => onInterruptTransportation(mutationInterrupt)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnInterruptLong}
              </ButtonLoader>
            )}
          </Mutation>
        </Col>
      </Row>

      <div>
        <Button
          onClick={onToggle}
        >
          {translations.common.close}
        </Button>
      </div>

    </FormBackground>
  </StyledModalInterrupt>
);


TransportationInterruptModalView.propTypes = {
  // data
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onDelayTransportation: func.isRequired,
  onDelayTransportationCompleted: func.isRequired,
  onDelayTransportationError: func.isRequired,
  onInterruptTransportation: func.isRequired,
  onInterruptTransportationCompleted: func.isRequired,
  onInterruptTransportationError: func.isRequired,
};


export default TransportationInterruptModalView;
