import { INPUT_TYPE, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../../../logic/form/common';


export const callFormStructure = {
  ...initForm,
  form: {
    platform: initInput({
      name: 'platform',
      type: INPUT_TYPE.SELECT,
    }),
  },
  values: {
    platform: null,
  },
};
