import React from 'react';
import { func, object, } from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Button from '../../../../atoms/Button/Button';


const ActionsView = ({
  // data
  translations,
  // methods
  onDelete,
}) => (
  <div>
    <h4>{translations.transportation.titleTransportationActions}</h4>

    <Row formGroup>
      <Col>
        <ButtonGrp>

          <Button
            onClick={onDelete}
            color="error"
          >
            {translations.common.delete}
          </Button>

        </ButtonGrp>
      </Col>
    </Row>
  </div>
);


ActionsView.propTypes = {
  translations: object.isRequired,
  onDelete: func.isRequired,
};


export default ActionsView;
