import React from 'react';
import {
  shape, string, object, func, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';
import FocusTrap from 'focus-trap-react';

import { MUTATION_LOGIN, } from '../../gql/mutations';
import { QUERY_LOGIN_TRANSLATION, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Input from '../../../../atoms/Input/Input';
import DDLanguages from '../../../../components/Header/DDLanguages';

import StyledLoginView from './styles/StyledLoginView';
import LogoDark from '../../../../styles/icons/app/LogoDark';


const LoginView = ({
  // data
  isValid,
  form,
  values,
  translations,
  // methods
  onChange,
  onKey,
  onLogin,
  onLoginCompleted,
  parseGraphQLError,
}) => (
  <StyledLoginView>
    <FocusTrap>
      <Row
        className="login"
        alignItems="center"
        justifyContent="center"
      >
        <Col XS={22} SM={16} MD={13} LG={11} HD={8}>
          <Mutation
            mutation={MUTATION_LOGIN}
            onCompleted={onLoginCompleted}
          >
            {(mutateLogin, { loading, error, }) => (
              <div
                role="none"
                className="login--panel"
                onKeyDown={(e) => onKey(e, () => onLogin(mutateLogin))}
              >
                <Row>
                  <Col XS={20} SM={20} className="login--title">
                    <LogoDark />
                  </Col>
                  <Col XS={4} SM={4} textAlign="right">
                    <DDLanguages />
                  </Col>
                </Row>

                <div className="login--info">
                  <div className="login--info-title">
                    {translations.authentication.loginInfoTitle}
                  </div>
                  <div className="login--info-text">
                    {translations.authentication.loginInfoText}
                  </div>
                </div>

                <div className="login--inputWrapper">
                  <Input
                    autoFocus
                    size="lg"
                    type="text"
                    value={values.username}
                    onChange={(e) => onChange(form.username.name, e.target.value)}
                    status={form.username.status}
                    placeholder={translations.form.username}
                  />
                </div>

                <div className="login--inputWrapper">
                  <Input
                    size="lg"
                    type="password"
                    value={values.password}
                    onChange={(e) => onChange(form.password.name, e.target.value)}
                    status={form.password.status}
                    placeholder={translations.form.password}
                  />
                </div>

                {error && (
                  <div className="login--msg-error">
                    {parseGraphQLError(error)}
                  </div>
                )}
                <Row style={{ justifyContent: 'center', }}>
                  <Col XS={24} SM={24} MD={12}>
                    <ButtonLoader
                      size="lg"
                      className="login--btn-login"
                      onClick={() => onLogin(mutateLogin)}
                      isLoading={loading}
                      disabled={!isValid || loading}
                      color="primary"
                    >
                      {translations.authentication.login}
                    </ButtonLoader>
                  </Col>
                </Row>
              </div>
            )}
          </Mutation>
        </Col>
      </Row>
    </FocusTrap>
  </StyledLoginView>
);


LoginView.propTypes = {
  translations: object.isRequired,
  isValid: bool.isRequired,
  form: shape({
    username: object.isRequired,
    password: object.isRequired,
  }).isRequired,
  values: shape({
    username: string.isRequired,
    password: string.isRequired,
  }).isRequired,
  onChange: func.isRequired,
  onKey: func.isRequired,
  onLogin: func.isRequired,
  onLoginCompleted: func.isRequired,
  parseGraphQLError: func.isRequired,
};


export default withTranslations(QUERY_LOGIN_TRANSLATION)(LoginView);
