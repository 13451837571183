import React from 'react';

import StyledSvg from '../styles/StyledSvg';

const Logo = ({ ...props }) => (
  <StyledSvg
    {...props}
    viewBox="0 0 610.66669 306.66666"
  >
    <g
      transform="matrix(1.3333333,0,0,-1.3333333,0,306.66667)"
    >
      <g transform="scale(0.1)">
        <path
          d="m 1514.61,613.883 32.42,-32.41 -4.05,-4.043 h 0.01 L 1410.22,444.664 h 441.49 V 886.16 l -104.17,-104.172 -12.18,-12.187 -20.23,-20.223 -0.22,-0.226 -267.42,267.408 -167.88,-167.877 235,-235"
          style={{ fill: '#f47216', }}
        />
        <path
          d="m 1851.71,1149.45 c 0,389.25 -315.54,704.78 -704.78,704.78 -389.246,0 -704.789,-315.53 -704.789,-704.78 0,-389.243 315.543,-704.786 704.789,-704.786 h 198.46 l 136.81,136.809 h 0.01 l -174.42,174.418 c -49.62,-20.305 -103.94,-31.508 -160.86,-31.508 -234.766,0 -425.071,190.305 -425.071,425.067 0,234.76 190.305,425.07 425.071,425.07 234.76,0 425.06,-190.31 425.06,-425.07 0,-56.81 -11.15,-111 -31.37,-160.54 l 174.51,-174.512 v -0.003 l 136.58,136.578 v 198.477"
          style={{ fill: '#f9f9f9', }}
        />
        <path
          d="M 2739.45,1136.99 H 2471.27 V 444.664 h -222.12 v 692.326 h -268.19 v 166.59 h 758.49 z m 145.89,-692.326 v 858.906 h 222.11 V 444.664 Z m 897.93,596.626 c 0,21.27 -4.31,39.61 -13,54.74 -8.82,15.38 -23.84,27.33 -45.09,36.04 -14.79,6.06 -31.9,9.84 -51.59,11.22 -19.7,1.38 -42.74,1.97 -68.92,1.97 h -78.96 V 916.063 h 67.15 c 34.84,0 64.01,1.691 87.42,5.117 23.64,3.461 43.37,11.16 59.27,23.433 15.2,11.739 26.27,24.778 33.28,39.184 6.96,14.332 10.44,33.473 10.44,57.493 z M 4130.61,444.664 H 3859.66 L 3625.93,760.109 H 3525.71 V 444.664 h -222.12 v 858.916 h 371.18 c 50.8,0 94.32,-2.97 130.75,-8.67 36.61,-5.71 70.68,-18.33 102.58,-37.41 32.28,-19.3 58.03,-44.16 76.99,-74.63 19.03,-30.56 28.56,-68.92 28.56,-114.99 0,-63.41 -14.58,-114.997 -43.92,-154.97 -29.33,-39.972 -71.28,-73.25 -125.82,-99.832 l 286.7,-368.414"
          style={{ fill: '#f9f9f9', }}
        />
      </g>
    </g>
  </StyledSvg>
);

export default Logo;
