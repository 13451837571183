import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import { unregister, } from './serviceWorker';

import './index.css';

document.title = 'QTir - central app';

// TODO: remake scrolling in time input - polyfill: scrollTo doesn't work on Edge
smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('root'));


unregister();
