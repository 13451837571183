import gql from 'graphql-tag';


/**
 * Roles
 */
export const MUTATION_CREATE_ROLE = gql`
  mutation CreateRole($values: roleInputType) {
    createRole(values: $values)
  }
`;

export const MUTATION_UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID, $values: roleInputType) {
    updateRole(id: $id, values: $values)
  }
`;

export const MUTATION_REMOVE_ROLE = gql`
  mutation DeleteRole($id: ID, $force: Boolean) {
    deleteRole(id: $id, force: $force)
  }
`;


/**
 * Users
 */
export const MUTATION_CREATE_USER = gql`
  mutation CreateUser($values: userInputType) {
    createUser(values: $values)
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation UpdateUser($id: ID, $values: userInputType) {
    updateUser(id: $id, values: $values)
  }
`;

export const MUTATION_REMOVE_USER = gql`
  mutation DeleteUser($id: ID) {
    deleteUser(id: $id)
  }
`;
