import gql from 'graphql-tag';


export const TRANSLATIONS_MODAL_DELETE = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            delete
            deleted
          }
        }
      }
    }
  }
`;
