import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import FormRow from '../../../../components/Form/FormRow';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import TextArea from '../../../../atoms/TextArea/TextArea';

import { QUERY_DD_PRIORITIES, } from '../../gql/queries';

const TransportationOtherForm = ({
  form,
  translations,
  values,
  onChange,
  languageId,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.priority}
      input={form.priority}
    >
      <Query
        query={QUERY_DD_PRIORITIES}
        variables={{ languageId, }}
      >
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.priority}
            options={
              (data && data.fetchPriorities)
                ? data.fetchPriorities
                : []
            }
            onChange={(option) => onChange(form.priority.name, option)}
            status={form.priority.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

    <FormRow
      label={translations.form.note}
      input={form.note}
    >
      <TextArea
        type="text"
        value={values.note}
        onChange={(e) => onChange(form.note.name, e.target.value)}
        status={form.note.status}
      />
    </FormRow>

  </Fragment>
);


TransportationOtherForm.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  form: shape({
    priority: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    note: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    priority: object,
    note: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationOtherForm;
