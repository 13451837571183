import React from 'react';
import {
  object, shape, string,
} from 'prop-types';

import { parseDateToHuman, } from '../../../../logic/date';
import { RESERVATION_TYPES, } from '../../../../globals';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ValueText from '../../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import FormBackground from '../../../../components/Form/FormBackground';
import RelatedTransportations from '../RelatedTransportations';


const InfoView = ({
  translations,
  reservation: {
    resNo,
    resTransportationType,
    resReservationType,
    resPlatform,
    resTimeFrom,
    resTimeTo,
    resDate,
  },
  company: {
    compName,
    compIdentificationNumber,
    compVatNumber,
    compStreet,
    compCity,
    compZip,
    compState,
    compContactPerson,
    compEmail,
    compPhoneNumber,
  },
  driver: {
    driverName,
    driverPhoneNumber,
  },
  truck: {
    truckRegistrationNumber,
  },
  trailer: {
    trailerRegistrationNumber,
  },
  prevTransport,
  nextTransport,
  priorityLevelName,
  note,
}) => (
  <FormBackground className="transportationDetail--form">
    <Row>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleReservationForm}</legend>

          <FormRowReadOnly label={translations.form.reservationNumber}>
            <ValueText>
              {resNo || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.transportationType}>
            <ValueText>
              {resTransportationType ? resTransportationType.name : '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.reservationType}>
            <ValueText>
              {resReservationType ? resReservationType.name : '-'}
            </ValueText>
          </FormRowReadOnly>

          {(resReservationType
            && resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
          ) && (
            <FormRowReadOnly label={translations.form.platform}>
              <ValueText>
                {resPlatform ? resPlatform.name : '-'}
              </ValueText>
            </FormRowReadOnly>
          )}

          {(resReservationType && resReservationType.id === RESERVATION_TYPES.DAY) && (
            <FormRowReadOnly label={translations.form.date}>
              <ValueText>
                {resDate ? parseDateToHuman(new Date(resDate), false) : '-'}
              </ValueText>
            </FormRowReadOnly>
          )}

          {(resReservationType && resReservationType.id !== RESERVATION_TYPES.DAY) && (
            <>
              <FormRowReadOnly label={translations.form.timeWindow}>
                <ValueText>
                  {`${resTimeFrom ? parseDateToHuman(new Date(resTimeFrom)) : '-'} - ${resTimeTo ? parseDateToHuman(new Date(resTimeTo)) : '-'}`}
                </ValueText>
              </FormRowReadOnly>
            </>
          )}

        </fieldset>

        <RelatedTransportations
          prev={prevTransport}
          next={nextTransport}
          translations={translations}
        />

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleCompanyForm}</legend>

          <FormRowReadOnly label={translations.form.name}>
            <ValueText>
              {compName || '-'}
            </ValueText>
          </FormRowReadOnly>

          <Row>
            <Col SM={12}>
              <FormRowReadOnly label={translations.form.identificationNumber}>
                <ValueText>
                  {compIdentificationNumber || '-'}
                </ValueText>
              </FormRowReadOnly>
            </Col>
            <Col SM={12}>
              <FormRowReadOnly label={translations.form.vatNumber}>
                <ValueText>
                  {compVatNumber || '-'}
                </ValueText>
              </FormRowReadOnly>
            </Col>
          </Row>

          <FormRowReadOnly label={translations.form.street}>
            <ValueText>
              {compStreet || '-'}
            </ValueText>
          </FormRowReadOnly>

          <Row>
            <Col SM={16}>
              <FormRowReadOnly label={translations.form.city}>
                <ValueText>
                  {compCity || '-'}
                </ValueText>
              </FormRowReadOnly>
            </Col>
            <Col SM={8}>
              <FormRowReadOnly label={translations.form.zip}>
                <ValueText>
                  {compZip || '-'}
                </ValueText>
              </FormRowReadOnly>
            </Col>
          </Row>

          <FormRowReadOnly label={translations.form.state}>
            <ValueText>
              {compState || '-'}
            </ValueText>
          </FormRowReadOnly>

          <h5>{translations.form.contactPerson}</h5>

          <FormRowReadOnly>
            <ValueText>
              {compContactPerson || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.email}>
            <ValueText>
              {compEmail || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.phoneNumber}>
            <ValueText>
              {compPhoneNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleDriverForm}</legend>

          <FormRowReadOnly label={translations.form.personName}>
            <ValueText>
              {driverName || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.phoneNumber}>
            <ValueText>
              {driverPhoneNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTruckForm}</legend>

          <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
            <ValueText>
              {truckRegistrationNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTrailerForm}</legend>

          <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
            <ValueText>
              {trailerRegistrationNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.otherform}</legend>

          <FormRowReadOnly label={translations.form.priority}>
            <ValueText>
              {priorityLevelName}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.note}>
            <ValueText>
              {note}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

      </Col>
    </Row>
  </FormBackground>
);


InfoView.propTypes = {
  translations: object.isRequired,
  reservation: shape({
    resNo: string.isRequired,
    resTransportationType: shape({
      name: string.isRequired,
    }).isRequired,
    resReservationType: shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    resPlatform: shape({
      name: string.isRequired,
    }),
    resTimeFrom: string,
    resTimeTo: string,
    resDate: string,
  }).isRequired,
  company: shape({
    compName: string.isRequired,
    compIdentificationNumber: string,
    compVatNumber: string,
    compStreet: string,
    compCity: string,
    compZip: string,
    compState: string,
    compContactPerson: string,
    compEmail: string,
    compPhoneNumber: string,
  }).isRequired,
  driver: shape({
    driverName: string,
    driverPhoneNumber: string,
  }).isRequired,
  truck: shape({
    truckRegistrationNumber: string,
  }).isRequired,
  trailer: shape({
    trailerRegistrationNumber: string,
  }).isRequired,
  prevTransport: string,
  nextTransport: string,
  priorityLevelName: string,
  note: string,
};


InfoView.defaultProps = {
  prevTransport: null,
  nextTransport: null,
  priorityLevelName: '-',
  note: '',
};


export default InfoView;
