import React, { Fragment, } from 'react';
import {
  string, object, shape, arrayOf,
} from 'prop-types';

import { parseTimeToHuman, parseDateToHuman, } from '../../../../logic/date';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FlowLine from '../../../../atoms/FlowLine/FlowLine';
import FlowLineText from '../../../../atoms/FlowLine/FlowLineText';
import FlowLineLeft from '../../../../atoms/FlowLine/FlowLineLeft';
import FlowLineMid from '../../../../atoms/FlowLine/FlowLineMid';
import FlowLineRight from '../../../../atoms/FlowLine/FlowLineRight';
import FlowChartAction from './FlowChartAction';
import FlowChartGateAction from '../TransportationDetail/FlowChartGateAction';

import StyledFlowChart from './styles/StyledFlowChart';


const renderGateThrough = (time, type) => {
  const data = {
    start: !!time,
    title: type === 'in' ? 'Vjezd do arálu' : 'Výjezd z areálu',
  };

  return (
    <li key="gateIn">
      <FlowLineLeft>
        {time && (
          <Fragment>
            <div className="transpDetail--flow-start">{parseTimeToHuman(new Date(time))}</div>
          </Fragment>
        )}
      </FlowLineLeft>
      <FlowLineMid
        full={!!time}
        color={time ? 'tertiary' : 'grey'}
      />
      <FlowLineRight>
        <FlowChartGateAction
          data={data}
        />
      </FlowLineRight>
    </li>
  );
};

/* eslint-disable react/no-array-index-key */

const FlowChart = ({
  tabs,
  transportationId,
  translations,
  languageId,
  arrival,
  departure,
}) => {
  const started = tabs.length > 1 && tabs[0].start;

  return (
    <StyledFlowChart>

      <h4>{translations.transportation.titleTransportationFlow}</h4>

      <Row>
        <Col MD_offset={2} MD={20} LG_offset={3} LG={18}>
          <FlowLine>
            <li>
              <FlowLineText>
                <h5>
                  {started
                    ? parseDateToHuman(new Date(started), false)
                    : translations.transportation.transportationNotStartedYet
                  }
                </h5>
              </FlowLineText>
            </li>

            {tabs.map((tab, i) => {
              let addGateIn = '';
              let addGateOut = '';
              if (i === 1) {
                addGateIn = renderGateThrough(arrival, 'in');
              }
              if (i === tabs.length - 1) {
                addGateOut = renderGateThrough(departure, 'out');
              }
              return (
                <Fragment>
                  {addGateIn}
                  {addGateOut}
                  <li key={`gf_${i}`}>
                    <FlowLineLeft>
                      {tab.start && (
                        <Fragment>
                          <div className="transpDetail--flow-start">{parseTimeToHuman(new Date(tab.start))}</div>
                          {tab.end && (
                            <div className="transpDetail--flow-end">{parseTimeToHuman(new Date(tab.end))}</div>
                          )}
                        </Fragment>
                      )}
                    </FlowLineLeft>
                    <FlowLineMid
                      round={i === 0 || i === tabs.length - 1}
                      hideLine={i === tabs.length - 1}
                      full={!!tab.end}
                      color={tab.start ? 'tertiary' : 'grey'}
                    />
                    <FlowLineRight>
                      <FlowChartAction
                        data={tab}
                        languageId={languageId}
                        translations={translations}
                        transportationId={transportationId}
                      />
                    </FlowLineRight>
                  </li>
                </Fragment>
              );
            })}
          </FlowLine>
        </Col>
      </Row>
    </StyledFlowChart>
  );
};


/* eslint-enable react/no-array-index-key */


FlowChart.propTypes = {
  tabs: arrayOf(shape({
    end: string,
    start: string,
  })),
  transportationId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  arrival: string,
  departure: string,
};

FlowChart.defaultProps = {
  tabs: [],
  arrival: null,
  departure: null,
};


export default FlowChart;
