import React from 'react';
import {
  arrayOf, string, object, func,
} from 'prop-types';

import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InfoView from './InfoView';
import FlowChart from './FlowChart';

import StyledTransportationDetail from './styles/StyledTransportationDetail';


const TransportationDetailView = ({
  // data
  detail,
  tabs,
  transportationId,
  languageId,
  translations,
  // methods
  onToggle,
}) => (
  <StyledTransportationDetail>
    <InfoView
      {...detail}
      translations={translations}
    />

    <FlowChart
      tabs={tabs}
      transportationId={transportationId}
      translations={translations}
      languageId={languageId}
      arrival={detail.arrival}
      departure={detail.departure}
    />

    <Row>
      <Col>
        <Button
          onClick={onToggle}
          shape="outline"
        >
          {translations.common.close}
        </Button>
      </Col>
    </Row>

  </StyledTransportationDetail>
);


TransportationDetailView.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default TransportationDetailView;
