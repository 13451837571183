import styled from 'styled-components';


const StyledPlatforms = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  ${(p) => p.capacity && `
    height: ${p.capacity * 7.5}rem;
  `}

  ul {
    padding: 0;
    margin: 0;
  }

  .storekeeperPlatformSection--closed {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.theme.grey.t500};
    margin-bottom: 1rem;
  }

  .storekeeperPlatformSection--empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    background: ${(p) => p.theme.grey.t50};
  }

  .storekeeperPlatformSection--empty-text {
    margin-bottom: 0.5rem;
    color: ${(p) => p.theme.grey.t500};
  }

  .storekeeperPlatformSection--empty button {
    width: 5rem;
  }

  .storekeeperPlatformSection--empty-btnCall {
    margin-left: 0.5rem;
  }
`;


export default StyledPlatforms;
