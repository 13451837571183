import React from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
import TextArea from '../../../../atoms/TextArea/TextArea';


const CompanyForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <>
    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.identificationNumber}
          input={form.identificationNumber}
        >
          <Input
            type="text"
            value={values.identificationNumber}
            onChange={(e) => onChange(form.identificationNumber.name, e.target.value)}
            status={form.identificationNumber.status}
          />
        </FormRow>
      </Col>
      <Col SM={12}>
        <FormRow
          label={translations.form.vatNumber}
          input={form.vatNumber}
        >
          <Input
            type="text"
            value={values.vatNumber}
            onChange={(e) => onChange(form.vatNumber.name, e.target.value)}
            status={form.vatNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

    <FormRow
      label={translations.form.supplierNumber}
      input={form.supplierNumber}
    >
      <Input
        type="text"
        value={values.supplierNumber}
        onChange={(e) => onChange(form.supplierNumber.name, e.target.value)}
        status={form.supplierNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.street}
      input={form.street}
    >
      <Input
        type="text"
        value={values.street}
        onChange={(e) => onChange(form.street.name, e.target.value)}
        status={form.street.status}
      />
    </FormRow>

    <Row>
      <Col SM={18}>
        <FormRow
          label={translations.form.city}
          input={form.city}
        >
          <Input
            type="text"
            value={values.city}
            onChange={(e) => onChange(form.city.name, e.target.value)}
            status={form.city.status}
          />
        </FormRow>
      </Col>
      <Col SM={6}>
        <FormRow
          label={translations.form.zip}
          input={form.zip}
        >
          <Input
            type="text"
            value={values.zip}
            onChange={(e) => onChange(form.zip.name, e.target.value)}
            status={form.zip.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.state}
          input={form.state}
        >
          <Input
            type="text"
            value={values.state}
            onChange={(e) => onChange(form.state.name, e.target.value)}
            status={form.state.status}
          />
        </FormRow>
      </Col>
    </Row>

    <FormRow
      label={translations.form.info}
      input={form.info}
    >
      <TextArea
        type="text"
        value={values.info}
        onChange={(e) => onChange(form.info.name, e.target.value)}
        status={form.info.status}
      />
    </FormRow>

    <h5>{translations.directory.subTitleContactInformation}</h5>

    <FormRow
      label={translations.form.contactPerson}
      input={form.contactPerson}
    >
      <Input
        type="text"
        value={values.contactPerson}
        onChange={(e) => onChange(form.contactPerson.name, e.target.value)}
        status={form.contactPerson.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.email}
      input={form.email}
    >
      <Input
        type="text"
        value={values.email}
        onChange={(e) => onChange(form.email.name, e.target.value)}
        status={form.email.status}
      />
    </FormRow>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.phoneNumber}
          input={form.phoneNumber}
        >
          <Input
            type="text"
            value={values.phoneNumber}
            onChange={(e) => onChange(form.phoneNumber.name, e.target.value)}
            status={form.phoneNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

  </>
);


CompanyForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    identificationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    supplierNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    vatNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    street: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    city: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    zip: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    state: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    contactPerson: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    email: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    phoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    identificationNumber: string,
    supplierNumber: string,
    vatNumber: string,
    street: string,
    city: string,
    zip: string,
    state: string,
    email: string,
    phoneNumber: string,
    contactPerson: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default CompanyForm;
